import axiosInstance from '../../api/axiosInstance';

/**
 * @typedef ReturnsHistoryItemProduct
 * @property {number} id
 * @property {number} status
 * @property {Object} supplier
 * @property {number} quantity
 * @property {string} productId
 * @property {string} externalProductId
 * @property {number} userId
 * @property {string} reason
 * @property {string} returnProductsId
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * @typedef ReturnsHistoryItem
 * @property {number} id Return order identifier
 * @property {number} status Status identifier
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} userId
 * @property {number} orgId
 * @property {string} comment
 * @property {ReturnsHistoryItemProduct[]} ReturnProductsItems
 */

/**
 * @typedef ReturnsBasket
 * @property {number} id
 * @property {number} name
 * @property {number} reason
 * @property {number} userId
 * @property {string} createdAt
 * @property {string} updatedAt
 */

/**
 * @typedef ReturnsBasketItem
 * @property {boolean} active
 * @property {string} article
 * @property {string} brand
 * @property {number} id
 * @property {string} minShippingRate
 * @property {string} name
 * @property {number} price
 * @property {number} productId
 * @property {number} quantity
 * @property {string} reason
 * @property {number} returnProductsBasketId
 * @property {string|Object|null} supplier
 * @property {number} tengePrice
 */

const state = () => ({
  /** @type string[] */
  reasons: [],
  /** @type Object */
  statuses: [],
  /** @type ReturnsBasket[] */
  baskets: [],
  /** @type ReturnsBasketItem[] */
  basketItems: [],
  /** @type number|null */
  basketId: null,
  returnsLoading: false,
});

const mutations = {
  SET_REASONS(state, reasons) {
    state.reasons = reasons;
  },
  SET_STATUSES(state, statuses) {
    state.statuses = statuses;
  },
  SET_BASKETS(state, baskets) {
    state.baskets = baskets;
  },
  ADD_BASKET(state, basketData) {
    state.baskets.push(basketData);
  },
  DELETE_RETURN_BASKET(state, basketId) {
    state.baskets.splice(state.baskets.findIndex(basket => basket === basketId), 1);
  },
  DELETE_RETURN_BASKET_ITEM(state, basketItemId) {
    state.basketItems.splice(state.basketItems.findIndex(item => item.id === basketItemId), 1);
  },
  SET_RETURN_BASKET_ID(state, basketId) {
    state.basketId = basketId;
  },
  SET_BASKET_ITEMS(state, basketItems) {
    state.basketItems = basketItems;
  },
  SET_RETURNS_LOADING(state, loadingState) {
    state.returnsLoading = loadingState;
  },
};

const actions = {
  activateReturnBasket({ commit, dispatch }, basketId) {
    commit('basket/SET_ACTIVE_BASKET', null, { root: true });
    commit('SET_RETURN_BASKET_ID', basketId);
    dispatch('GetReturnProductsBasket', basketId);
  },
  /**
   * Получение списка корзин возвратов
   * @return {Promise<unknown>}
   */
  async GetReturnProductsBaskets({ commit }) {
    return new Promise(async (resolve, reject) => {
      await axiosInstance('/api/returnProductsBasket')
        .then((response) => {
          commit('SET_BASKETS', response);
          resolve(response);
        }).catch(reject);
    });
  },
  /**
   * Удаление корзины возврата
   * @param _
   * @param returnBasketId
   * @return {Promise<unknown>}
   * @constructor
   */
  DeleteReturnProductBasket(_, returnBasketId) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `/api/returnProductsBasket/${returnBasketId}`,
        method: 'delete',
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(new Error(err.message || err));
        });
    });
  },
  /**
   * Создание корзины возврата
   * @param _
   * @param {string} name Наименование корзины
   * @return {Promise<ReturnsBasket>}
   */
  AddReturnProductsBasket({ commit }, name) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/returnProductsBasket',
        method: 'post',
        data: {
          name
        }
      })
        .then((response) => {
          commit('ADD_BASKET', response);
          commit('basket/SET_ACTIVE_BASKET', null, { root: true });
          commit('SET_RETURN_BASKET_ID', response.id);
          resolve(response);
        })
        .catch((err) => {
          reject(new Error(err.message || err));
        });
    });
  },
  /**
   * Получение корзины возврата
   * @param _
   * @param {number} returnProductsBasketId Идентификатор корзины
   * @param setItems
   * @return {Promise<unknown>}
   */
  GetReturnProductsBasket({ commit }, returnProductsBasketId, setItems = true) {
    return new Promise((resolve, reject) => {
      axiosInstance(`/api/returnProductsBasketItems/${returnProductsBasketId}`)
        .then((resp) => {
          if (setItems) {
            commit('SET_BASKET_ITEMS', resp);
          }
          resolve(resp);
        })
        .catch((err) => {
          reject(new Error(err.message || err));
        });
    });
  },
  /**
   * Добавление товара в корзину возврата
   * @param _
   * @param {number|Object} productId Идентификатор товара
   * @param supplier
   * @param {number} quantity Количество
   * @param {number} returnProductsBasketId Идентификатор корзины
   * @param {string|null} reason Описание причины возврата
   * @return {Promise<unknown>}
   */
  AddItemToReturnProductsBasket({ dispatch }, {
    productId = undefined,
    supplier = undefined,
    quantity = 1,
    returnProductsBasketId,
    reason = null
  }) {
    return new Promise((resolve, reject) => {
      const data = {
        quantity,
        returnProductsBasketId,
        reason,
        productId,
        supplier
      };
      axiosInstance({
        url: '/api/returnProductsBasketItems',
        method: 'post',
        data
      })
        .then(() => {
          resolve();
          dispatch('GetReturnProductsBasket', returnProductsBasketId);
        })
        .catch((err) => {
          reject(new Error(err.message || err));
        });
    });
  },
  /**
   * Изменение товара в корзине возврата
   * @param _
   * @param returnProductsBasketItemId Идентификатор товара в корзине возврата
   * @param quantity Количество товара
   * @return {Promise<unknown>}
   */
  ChangeItemInReturnProductsBasket(_, {
    returnProductsBasketItemId,
    quantity = 1
  }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `/api/returnProductsBasketItems/${returnProductsBasketItemId}`,
        method: 'put',
        data: {
          quantity,
        }
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(new Error(err.message || err));
        });
    });
  },
  /**
   * Удаление товара из корзины возврата
   * @param _
   * @param returnProductsBasketItemId Идентификатор товара в корзине возврата
   * @return {Promise<unknown>}
   */
  DeleteItemFromReturnProductsBasket(_, returnProductsBasketItemId) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `/api/returnProductsBasketItems/${returnProductsBasketItemId}`,
        method: 'delete'
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(new Error(err.message || err));
        });
    });
  },
  /**
   * Получение статусов возврата товаров
   * @param commit
   * @return {Promise<unknown>}
   */
  async GetStatuses({ commit }) {
    return new Promise(async (resolve, reject) => {
      await axiosInstance('/api/returnProducts/status')
        .then((resp) => {
          commit('SET_STATUSES', resp);
          resolve(resp);
        }).catch(reject);
    });
  },
  /**
   * Получение причин возврата товаров
   * @param commit
   * @return {Promise<unknown>}
   */
  async GetReasons({ commit }) {
    return new Promise(async (resolve, reject) => {
      await axiosInstance('/api/returnProducts/reasons')
        .then((resp) => {
          commit('SET_REASONS', resp);
          resolve(resp);
        }).catch(reject);
    });
  },
  /**
   * Получение истории возвратов
   * @param _
   * @param {number} max
   * @param {number} offset
   * @param {number} status
   * @return {Promise<{count: Number, rows:ReturnsHistoryItem[]}>}
   * @constructor
   */
  async GetReturnProducts({ commit }, { max = undefined, offset = undefined, status = undefined }) {
    return new Promise(async (resolve, reject) => {
      commit('SET_RETURNS_LOADING', true);
      await axiosInstance({
        url: '/api/returnProducts',
        params: {
          max, offset, status
        }
      })
        .then((resp) => {
          resolve(resp);
        }).catch(reject)
        .finally(() => {
          commit('SET_RETURNS_LOADING', false);
        });
    });
  },
  /**
   * Получение товаров для истории возвратов
   * @param _
   * @param {number} returnId Идентификатор возврата
   * @return {Promise<ReturnsHistoryItemProduct[]>}
   */
  async GetReturnProductItems(_, returnId) {
    return new Promise(async (resolve, reject) => {
      await axiosInstance({
        url: '/api/returnProductsItems',
        params: {
          returnProductsId: returnId
        }
      })
        .then((resp) => {
          resolve(resp);
        }).catch(reject);
    });
  },
  /**
   * Создание возврата из корзины возврата
   * @param _
   * @param {Array} returnBasketIds
   * @param {number} orgId
   * @param {string} comment
   * @return {Promise<unknown>}
   * @constructor
   */
  async PostReturnProducts({
    commit, dispatch, state, rootState
  }, { returnBasketIds, orgId, comment }) {
    return new Promise(async (resolve, reject) => {
      await axiosInstance({
        url: '/api/returnProducts',
        method: 'post',
        data: {
          returnProductsBaskets: returnBasketIds,
          orgId,
          comment,
        }
      })
        .then((resp) => {
          returnBasketIds.forEach((returnBasketId) => {
            commit('DELETE_RETURN_BASKET', returnBasketId);
          });
          if (state.baskets.length) {
            dispatch('activateReturnBasket', state.baskets[0].id);
          } else if (rootState.basket.multiBasket.length) {
            commit('SET_RETURN_BASKET_ID', null);
            commit('basket/SET_ACTIVE_BASKET', rootState.basket.multiBasket[0].id, { root: true });
          }
          resolve(resp);
        }).catch(reject);
    });
  },
  async basketHasProductId({ state }, productId) {
    if (typeof productId === 'object') {
      return state.basketItems.find(item => item === productId) !== undefined;
    }
    return state.basketItems.find(item => item.productId === productId) !== undefined;
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
