<template>
  <div class="account">
    <div style="width: 65%">
      <div style="display:flex;justify-content: space-between;align-items: center">
        <div>
          <h4>Активные пользователи</h4>
        </div>

        <div>
          <el-button
            @click="$router.push('/dashboard/user-create')"
            size="small"
            type="primary"
          >
            Создать пользователя
          </el-button>
        </div>
      </div>
      <el-table
        :data="users.activeUsers"
        style="width: 100%"
      >
        <el-table-column
          prop="email"
          label="Email"
          width="180"
        />
        <el-table-column
          prop="name"
          label="Имя"
          width="180"
        />

        <el-table-column
          label="Дата создания"
          align="center"
          width="100"
        >
          <template v-slot="scope">
            {{ scope.row.createdAt.slice(0,10) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Сделать неактивным"
          align="right"
        >
          <template v-slot="scope">
            <div style="padding-right: 40px">
              <el-button
                @click="deactivate(scope.row.name)"
                icon="el-icon-close"
                size="mini"
                type="danger"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <br>

      <h4>Неактивные пользователи</h4>
      <el-table
        :data="users.inactiveUsers"
        style="width: 100%"
      >
        <el-table-column
          prop="email"
          label="Email"
          width="180"
        />
        <el-table-column
          prop="name"
          label="Имя"
          width="180"
        />

        <el-table-column
          label="Дата создания"
          align="center"
          width="100"
        >
          <template v-slot="scope">
            {{ scope.row.createdAt.slice(0,10) }}
          </template>
        </el-table-column>

        <el-table-column
          label="Сделать активным"
          align="right"
        >
          <template v-slot="scope">
            <div style="padding-right: 40px">
              <el-button
                @click="activate(scope.row.name)"
                icon="el-icon-check"
                size="mini"
                type="primary"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserManagement',
  data() {
    return {

    };
  },
  computed: {
    users() {
      return this.$store.state.accounts.myUsers;
    }
  },
  async mounted() {
    await this.$store.dispatch('accounts/GetMyUsers');
  },
  methods: {
    deactivate(name) {
      this.$store.dispatch('accounts/DisactivateUser', name);
    },
    activate(name) {
      this.$store.dispatch('accounts/ActivateUser', name);
    }
  }
};
</script>

<style scoped>
  .account {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
  }

</style>
