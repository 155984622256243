<template>
  <div>
    <n-table
      :data="data"
      :columns="tableColumns"
      class="table"
    >
      <template v-slot:info="{ row }" v-once>
        <el-button
          @click="showProductInfo(row)"
          icon="el-icon-search"
          size="mini"
          circle
        />
      </template>
      <template v-slot:view="{ row }">
        <el-button
          icon="el-icon-arrow-right"
          plain
          size="mini"
          type="primary"
          @click="viewModel(row)"
        />
      </template>
    </n-table>
    <el-dialog
      :title="dialogData.name"
      :visible.sync="dialogVisible"
      append-to-body
      width="90%"
      style="max-width: 800px;margin: 10px auto"
    >
      <div class="dialog__inner">
        <div v-for="(row, i) in columns.filter(item => item.prop !== 'name')" :key="`dialog-row---${i}`">
          <b>{{ colNamesTranslate[row.name] || row.name }}:</b> {{ dialogData[row.prop] }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const colNamesTranslate = {
  MVS: 'Двигатель',
  engineType: 'Двигатель',
  engineIndex: 'Модель двигателя',
  MVSDesc: 'Модель двигателя',
  displacement: 'Объём двигателя',
  gearboxType: 'КПП',
  power: 'Мощность',
  drive: 'Руль',
  steering: 'Руль',
  variant: 'Вариация',
  bodyworkType: 'Кузов',
  bodyStyle: 'Кузов',
  fuel: 'Топливо',
  trimlevel: 'Отделка',
  otherFeatures: 'Комплектация',
  series_code: 'Код серии',
  series_description: 'Серия',
  model_year: 'Модельный год',
  modelYear: 'Модельный год',
  prodRange: 'Года производства',
  family: 'Семейство',
  vehicleCategory: 'Категория ТС',
  modelcode: 'Код модели',
  destinationRegion: 'Рынок',
  market: 'Рынок',
  catalogNo: 'Каталожный номер',
};

export default {
  name: 'LaximoModelsTable',
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    colNamesTranslate,
    dialogVisible: false,
    dialogData: {},
  }),
  computed: {
    ...mapState('ui', ['windowSize']),
    tableColumns() {
      const length = this.windowSize.width > 700 ? 4 : 2;
      const isBigTable = this.columns.length > length;
      let cols = isBigTable ? this.columns.slice(0, length) : this.columns;
      cols = cols.map(item => ({ ...item, name: colNamesTranslate[item.name] || item.name }));
      if (isBigTable) cols.push({
        name: '', prop: 'info', width: '30px', align: 'center'
      });
      cols.push({
        name: 'Найти', prop: 'view', width: '60px', align: 'center'
      });
      return cols;
    }
  },
  methods: {
    viewModel(row) {
      this.$emit('viewModel', row);
    },
    showProductInfo(row) {
      this.dialogData = row;
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped lang="sass">
  .table
    margin-bottom: 10px
  .text-overflow
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  .dialog__inner
    padding: 0 10px 10px
</style>
