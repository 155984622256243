<template>
  <span class="baskets" v-if="multiBasket && basketsArray.length">
    <template v-for="(item, i) in basketsArray">
      <span v-if="item[1]" class="basket" :key="`basket---${i}`" :style="{ backgroundColor: getColor(item[0]) }">
        {{ item[1] }}
      </span>
    </template>
  </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BasketIndicator',
  props: {
    baskets: {
      type: [Object, String, null],
      required: true,
    }
  },
  computed: {
    ...mapState('basket', ['multiBasket']),
    basketsArray() {
      if (typeof this.baskets !== 'object' || this.baskets === null) return [];
      return Object.entries(this.baskets);
    }
  },
  methods: {
    getColor(id) {
      const basket = this.multiBasket.find(item => Number(item.id) === Number(id));
      if (!basket || !basket.colour) return 'unset';
      return `rgb(${basket.colour})`;
    }
  }
};
</script>

<style scoped lang="sass">
.baskets
  display: inline-flex
  flex-flow: nowrap
  align-items: center
  height: 13px

.basket
  display: flex
  flex-flow: nowrap
  align-items: center
  justify-content: center
  font-size: .9em
  font-weight: bold
  height: 15px
  padding: 0 3px
  min-width: 15px
  text-align: center
  border-radius: 3px
  margin-left: 5px
  &:first-child
    margin-left: 10px

.dot
  display: block
  height: 9px
  width: 9px
  border-radius: 50%
  margin-right: 3px
</style>
