<template>
  <div class="promo-action">
    <div class="promo-action__main">
      <div class="promo-action__name">
        {{ name }}
      </div>
      <div>В корзине на <price-output :price="normalizePrice(value)" /></div>
    </div>
    <div class="promo-action__value">
      <price-output :price="normalizePrice(sum)" />
    </div>
    <el-progress type="dashboard" :percentage="percent" :color="colors" width="60" />
  </div>
</template>

<script>
import { normalizePrice } from 'utils/normalizePrice';

export default {
  name: 'PromoActionItem',
  props: {
    name: {
      type: String,
      required: true
    },
    sum: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      colors: [
        { color: '#f56c6c', percentage: 30 },
        { color: '#e6a23c', percentage: 60 },
        { color: '#5cb87a', percentage: 100 },
      ]
    };
  },
  computed: {
    percent() {
      return Math.round((100 * this.value) / this.sum);
    }
  },
  methods: {
    normalizePrice,
  },
};
</script>

<style scoped lang="sass">
  .promo-action
    display: grid
    grid-template-columns: 1fr auto auto
    justify-items: start
    grid-column-gap: 20px
    padding: 10px 20px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    width: 100%
    &__name
      font-weight: bold
      font-size: 16px
      margin-bottom: 15px
    &__value
      align-self: center
</style>
