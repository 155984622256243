<template>
  <div :class="['button-group', {'button-group_visible': visible}]">
    <button
      class="button-group__button"
      @click.prevent.stop="showProductInfo(row)"
    >
      <span class="el-icon-loading" v-if="row.productId && productInfoModalLoadingIndex === row.productId" />
      Инфо
    </button>
    <button
      class="button-group__button"
      @click.prevent.stop="SET_ANALOGS_FOR_ID(row.productId)"
      v-if="row.productId"
    >
      Аналоги
    </button>
    <button
      class="button-group__button"
      @click.prevent.stop="addWishList(row)"
      v-if="row.productId"
    >
      В ожидание
    </button>
    <button
      class="button-group__button"
      @click.prevent.stop="moveItem = row"
    >
      <el-tooltip effect="dark" content="Перенести в другую корзину">
        <i class="el-icon-shopping-cart-1" />
      </el-tooltip>
    </button>

    <product-info-modal
      :brand="JSON.parse(row.supplier).brand"
      :article="JSON.parse(row.supplier).article"
      v-if="showSupplierProductInfoModal"
    />
    <el-dialog
      :title="`Перенести товар «${moveItem ? moveItem.name : ''}» в корзину?`"
      :visible="moveItem !== null"
      @close="moveItem = null"
      append-to-body
      width="400px"
    >
      <el-select v-model="moveBasketId" style="width: 100%" placeholder="Выбрать корзину">
        <el-option
          :label="basket.name"
          :value="basket.id"
          v-for="basket in multiBasket.filter(basketToFilter => basketToFilter.id !== activeBasketData.id)"
          :key="basket.id"
        />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="moveItem = null"
        >Отменить</el-button>
        <el-button
          type="primary"
          @click="moveProduct"
          :disabled="!moveBasketId"
          :loading="moveLoading"
        >Перенести</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapActions, mapMutations, mapState
} from 'vuex';
import ProductInfoModal from '@/components/tecdoc/productInfoModal';
import genProductInfo from '@/components/product-table/utils/genProductInfo';

export default {
  name: 'ProductRowButtons',
  components: { ProductInfoModal },
  props: {
    row: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    basketId: {
      type: Number,
      default: null,
      required: true
    }
  },
  data() {
    return {
      showSupplierProductInfoModal: false,
      moveItem: null,
      moveBasketId: null,
      moveLoading: false,
    };
  },
  computed: {
    ...mapState('products', ['productInfoModalLoadingIndex']),
    ...mapState('basket', ['multiBasket']),
    activeBasketData() {
      return this.multiBasket.find(basket => basket.id === this.basketId);
    }
  },
  methods: {
    ...mapMutations('products', ['SET_ANALOGS_FOR_ID', 'SET_PRODUCT_INFO_MODAL_DATA', 'SET_PRODUCT_INFO_MODAL_LOADING_INDEX']),
    ...mapActions('products', ['GetProductInfo']),
    ...mapActions('wishlist', ['addToWishList']),
    ...mapActions('basket', ['moveItemToBasket']),
    showProductInfo(row) {
      this.SET_PRODUCT_INFO_MODAL_LOADING_INDEX(row.productId);
      if (!row.productId) {
        this.showSupplierProductInfo(row);
        this.SET_PRODUCT_INFO_MODAL_LOADING_INDEX(null);
        return;
      }
      this.GetProductInfo(row.productId)
        .then((res) => {
          this.SET_PRODUCT_INFO_MODAL_DATA(genProductInfo(res, row));
        })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось получить данные о товаре'); })
        .finally(() => { this.SET_PRODUCT_INFO_MODAL_LOADING_INDEX(null); });
    },
    showSupplierProductInfo() {
      this.showSupplierProductInfoModal = true;
    },
    async addWishList(row) {
      this.addToWishList({
        productId: row.productId,
        quantity: row.quantity,
        basketName: this.activeBasketData?.name || '',
      }).then(() => { this.$message.success('Товар добавлен в лист ожидания'); })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось добавить товар в лист ожидания'); });
    },
    async moveProduct() {
      this.moveLoading = true;
      this.moveItemToBasket({
        basketItemId: this.moveItem.basketItemId || this.moveItem.id,
        newBasketId: this.moveBasketId
      })
        .then(() => {
          this.$message.success('Товар перемещён');
          this.moveItem = null;
        })
        .catch((err) => {
          this.$alert(err.message || err, 'Не удалось переместить товар');
        }).finally(() => {
          this.moveLoading = false;
        });
    },
  }
};
</script>

<style lang="sass">
.button-group
  visibility: hidden
  flex-flow: nowrap
  margin-left: 10px
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5)
  opacity: 0
  transition: opacity .1s
  z-index: 1
  display: inline-flex
  border-radius: 3px
  &__button
    font-size: 12px
    display: flex
    flex-flow: nowrap
    line-height: 1
    white-space: nowrap
    cursor: pointer
    background: #fff
    border: 1px solid #DCDFE6
    margin-left: -1px
    color: #606266
    padding: 3px 8px
    transition: 0.2s
    &:first-child
      border-top-left-radius: 3px
      border-bottom-left-radius: 3px
      margin-left: 0
    &:last-child
      border-top-right-radius: 3px
      border-bottom-right-radius: 3px
    &:hover, &:active, &:focus
      color: #1f75a8
      border-color: #1f75a8
      background: #def0ff
      z-index: 1
    span
      margin-right: 5px
.n-table-row:hover .button-group,
.n-table-row_active .button-group,
.button-group_visible
  transition: opacity .1s
  visibility: visible
  opacity: 1
.button-group_visible
  margin-left: 0
</style>
