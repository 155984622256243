<template>
  <li class="app-tree__item">
    <div class="app-tree__wrapper">
      <button
        v-if="node.children && node.children.length"
        type="button"
        @click.prevent="toggleButtonClickHandler"
        :aria-label="collapsed ? 'Открыть' : 'Закрыть'"
        class="app-tree__toggle"
        :class="{ 'app-tree__toggle--open': !collapsed }"
      />
      <button
        type="button"
        @click="buttonClickHandler"
        class="app-tree__button"
        :class="{
          'app-tree__button--empty': !node.children.length,
          'app-tree__button--current': currentCategory === node.id
        }"
      >
        {{ node.name }}
      </button>
    </div>
    <template v-if="node.children && node.children.length">
      <app-tree-transition>
        <ul class="app-tree__sub" v-show="!collapsed">
          <template v-for="children of node.children">
            <app-tree-node :node="children" :key="children.id" @click="nodeClickHandler" />
          </template>
        </ul>
      </app-tree-transition>
    </template>
  </li>
</template>

<script>
import AppTreeTransition from './app-tree-transition.vue';

export default {
  name: 'AppTreeNode',
  components: {
    AppTreeTransition
  },
  props: {
    node: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      collapsed: this.node.collapsed ?? true
    };
  },
  computed: {
    currentCategory() {
      return this.$store.state.categories.currentCategory;
    }
  },
  methods: {
    buttonClickHandler() {
      this.$emit('click', this.node.id);
    },
    nodeClickHandler(id) {
      this.$emit('click', id);
    },
    toggleButtonClickHandler() {
      this.collapsed = !this.collapsed;
    }
  },
};
</script>

<style scoped>
.app-tree__item > .app-tree__sub {
  padding: 0;
  padding-left: 10px;
  margin: 0;
  width: 250px;
  list-style: none;
}

.app-tree__wrapper {
  display: flex;
  word-wrap: break-word;
  align-items: center;
}

.app-tree__wrapper > * {
  flex-shrink: 0;
}

.app-tree__toggle {
  box-sizing: content-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  padding-top: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  margin: 0;
  margin-right: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.app-tree__toggle::before {
  content: " ";
  position: absolute;
  top: calc(50% - (8px / 2));
  left: calc(50% - (8px / 2));
  display: block;
  width: 8px;
  height: 8px;
  background-image: linear-gradient(
    135deg,
    transparent 0%,
    transparent 50%,
    #1f75a8 50%,
    #1f75a8 100%
  );
  transition-property: transform;
  transition-duration: 250ms;
  transition-timing-function: ease;
  transform-origin: center center;
  transform: rotate(-45deg);
}

.app-tree__toggle--open::before {
  transform: rotate(45deg);
}

.app-tree__button {
  position: relative;
  display: block;
  width: 220px;
  padding: 8px 0;
  border: none;
  background-color: transparent;
  font-size: 11px;
  font-family: 'Nunito', sans-serif;
  text-align: left;
  outline: none;
  user-select: none;
  cursor: pointer;
}

.app-tree__button::before {
  content: " ";
  position: absolute;
  display: none;
}

.app-tree__button::before {
  top: calc(50% - (7px / 2));
  left: 10px;
  width: 7px;
  height: 7px;
  border: 1px solid #1f75a8;
  border-radius: 50%;
  background-color: transparent;
}

.app-tree__button--empty {
  padding-left: 25px;
}

.app-tree__button--empty::before {
  display: block;
}

.app-tree__button:focus,
.app-tree__button:hover {
  background-color: #f6f9fc;
}

.app-tree__button--current {
  font-weight: bold;
}
</style>
