<template>
  <div>
    <div class="m-md">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <span class="breadcrumb-item" @click="navigateToMain">Общий каталог (TecDoc)</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span class="breadcrumb-item" @click="navigateToMain">Бренды</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <TecDocOffline />

    <BrandsComponent v-loading.lock="loading" />
  </div>
</template>

<script>
import TecDocOffline from '@/components/tecdoc/offline';
import BrandsComponent from '../../components/abcp/Brands';

export default {
  name: 'Brands',
  components: {
    TecDocOffline,
    BrandsComponent
  },
  metaInfo: {
    title: 'Бренды TecDoc'
  },
  computed: {
    loading() {
      return this.$store.state.tecdoc.loading;
    },
  },
  async created() {
    await this.$store.dispatch('tecdoc/GetAutoBrand', {});
  },
  methods: {
    async navigateToMain() {
      if (this.$route.path !== '/tecdoc/brand') await this.$router.push('/tecdoc/brand');
      this.$store.commit('tecdoc/SET_HAS_SEARCH_REQUEST', false);
    }
  },
};
</script>
