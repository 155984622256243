<template>
  <div class="shop">
    <page-header back-button @back-button-click="$router.push({ name: 'shops' })">
      <template v-if="shop">
        Склад «{{ shop.name }}»
      </template>
      <template v-slot:subtitle v-if="products.length || productsLoading">
        <template v-if="productsLoading">
          Загрузка
        </template>
        <template v-else>
          {{ products.length }} {{ declOfNum(products.length, ['товар', 'товара', 'товаров']) }}
        </template>
      </template>
    </page-header>

    <el-empty
      v-if="!products.length && !productsLoading" description="Нет товаров"
    />
    <n-table
      :loading="productsLoading"
      :data="products"
      v-if="productsLoading || products.length"
      :default-sort="sort"
      :columns="[
        { name: '', prop: 'edit', width: '50px', align: 'right' },
        { name: 'Бренд', prop: 'brand', width: '120px' },
        { name: 'Код 1С', prop: 'externalId', width: '120px' },
        { name: 'Название', prop: 'name', width: 'minmax(200px, 1fr)', sortable: true },
        { name: 'Остаток', prop: 'quantity', width: '100px', align: 'right', sortable: true },
        { name: '', prop: 'actions', width: '160px', align: 'center' },
      ]"
      @sort-change="sortHandler"
    >
      <template v-slot:edit="{ row }">
        <el-tooltip effect="dark" content="Изменить">
          <el-button
            size="mini"
            @click.prevent.stop="editProductHandler(row)"
            icon="el-icon-edit"
          />
        </el-tooltip>
      </template>
      <template v-slot:brand="{ row }">
        {{ row.brand }}
      </template>
      <template v-slot:externalId="{ row }">
        {{ row.externalId }}
      </template>
      <template v-slot:name="{ row }">
        {{ row.name }}
      </template>
      <template v-slot:quantity="{ row }">
        <template v-if="row.quantity">
          {{ row.quantity }}
        </template>
      </template>
      <template v-slot:actions="{ row }">
        <div style="display:flex;align-items:center;gap:5px">
          <n-input-number
            :value="productQuantityChanges[row.id] || 1"
            @change="setQuantityHandler(row, $event)"
          />
          <el-tooltip content="Реализация товара">
            <el-button
              size="mini"
              @click.prevent.stop="saveQuantityHandler(row, false)"
              icon="el-icon-upload2"
            />
          </el-tooltip>

          <el-tooltip content="Приход товара">
            <el-button
              size="mini"
              @click.prevent.stop="saveQuantityHandler(row)"
              icon="el-icon-download"
            />
          </el-tooltip>
        </div>
      </template>
    </n-table>
    <el-dialog
      modal-append-to-body
      :visible.sync="isProductCreationModalVisible"
      title="Товар"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        status-icon
        @submit.native.prevent="createProductHandler"
      >
        <el-form-item label="Название" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="OEM" prop="OEM">
          <el-input v-model="form.OEM" />
        </el-form-item>
        <el-form-item label="Код 1С" prop="externalId">
          <el-input v-model="form.externalId" />
        </el-form-item>
        <el-form-item label="Артикул" prop="article">
          <el-input v-model="form.article" />
        </el-form-item>
        <el-form-item
          label="Кратность"
          :rules="[
            { type: 'number', message: 'Только числа'}
          ]"
          prop="minShippingRate"
        >
          <el-input v-model.number="form.minShippingRate" />
        </el-form-item>
        <el-form-item label="Бренд" prop="brand">
          <el-input v-model="form.brand" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button-group>
          <el-button @click="isProductCreationModalVisible = false">Отменить</el-button>
          <el-button type="primary" @click="createProductHandler">Сохранить</el-button>
        </el-button-group>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header/page-header';
import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
import declOfNum from '../../utils/declOfNum';

/**
 * @returns {ShopProduct}
 */
const defaultFormValues = () => ({
  id: null,
  name: '',
  OEM: '',
  stockbalance: 0,
  externalId: '',
  article: '',
  minShippingRate: 1,
  brand: '',
});

export default {
  name: 'Shops',
  components: { PageHeader },
  metaInfo: {
    title: 'Мои склады'
  },
  data: () => ({
    /** @type {Shop} */
    shop: null,
    /** @type {ShopProduct|null} */
    form: defaultFormValues(),
    rules: {
      name: [
        { required: true, message: 'Укажите наименование', trigger: 'blur' },
      ],
      OEM: [
        { required: true, message: 'Укажите OEM', trigger: 'blur' }
      ],
      externalId: [
        { required: true, message: 'Укажите код 1С', trigger: 'blur' }
      ],
      article: [
        { required: true, message: 'Укажите артикул', trigger: 'blur' }
      ],
      brand: [
        { required: true, message: 'Укажите бренд', trigger: 'blur' }
      ],
    },
    isProductCreationModalVisible: false,
    productQuantityChanges: {},
    sort: { prop: 'name', order: 'ASC' },
  }),
  computed: {
    ...mapState('personalCabinet', ['products', 'productsLoading']),
  },
  async mounted() {
    await this.getShop(Number(this.$route.params.shopId))
      .then((shopData) => {
        this.shop = shopData;
      }).catch((e) => {
        console.error(e);
        this.$message.error('Возникла ошибка при получении информации о складе');
      });
    await this.getProducts();
  },
  methods: {
    declOfNum,
    ...mapActions('personalCabinet', [
      'getShop', 'createProduct', 'getProduct',
      'editProduct', 'setStockBalance', 'setStockBalanceFromOrders',
    ]),
    ...mapActions('personalCabinet', {
      getProductsHandle: 'getProducts'
    }),
    async getProducts() {
      await this.getProductsHandle(
        {
          shopId: this.shop.id,
          sortField: this.sort.prop,
          sortOrder: this.sort.order,
        }
      ).catch((e) => {
        this.$message.error('Возникла ошибка при получении товаров склада');
        console.error(e);
      });
    },
    createButtonClickHandler() {
      this.form = defaultFormValues();
      this.isProductCreationModalVisible = true;
    },
    async createProductHandler() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        if (this.form.id) {
          await this.editProduct({
            productId: this.form.id,
            name: this.form.name,
            OEM: this.form.OEM,
            externalId: this.form.externalId,
            article: this.form.article,
            minShippingRate: this.form.minShippingRate,
            brand: this.form.brand,
          }).then(() => {
            this.$message.success('Товар изменён');
            this.getProducts();
            this.form = defaultFormValues();
            this.isProductCreationModalVisible = false;
          })
            .catch((e) => {
              console.error(e);
              this.$message.error('Возникла ошибка при создании товара');
            });
        } else {
          await this.createProduct({
            name: this.form.name,
            OEM: this.form.OEM,
            externalId: this.form.externalId,
            article: this.form.article,
            minShippingRate: this.form.minShippingRate,
            brand: this.form.brand,
          }).then(() => {
            this.$message.success('Товар добавлен');
            this.form = defaultFormValues();
            this.isProductCreationModalVisible = false;
            this.getProducts();
          })
            .catch((e) => {
              this.$message.error(e || 'Возникла ошибка при создании товара');
            });
        }
        return true;
      });
    },
    /**
     * @param {ShopProduct} product
     */
    async editProductHandler(product) {
      this.form.id = product.id;
      this.form.name = product.name;
      this.form.OEM = product.OEM;
      this.form.externalId = product.externalId;
      this.form.article = product.article;
      this.form.brand = product.brand;
      this.isProductCreationModalVisible = true;
    },
    /**
     * @param {ShopProduct} product
     * @param {boolean} positive
     */
    async saveQuantityHandler(product, positive = true) {
      const stockBalance = product?.quantity || 0;
      const quantity = this.productQuantityChanges[product.id] || 1;

      await this.setStockBalance(
        {
          productId: product.id,
          stockBalance: positive ? stockBalance + quantity : stockBalance - quantity,
          shopId: this.shop.id,
        }
      ).catch((e) => {
        console.error(e);
        this.$message.error('Возникла ошибка при изменении остатков товара');
      });
      this.setQuantityHandler(product, 1);
      await this.getProducts();
    },
    /**
     * @param {ShopProduct} product
     * @param {number} value
     */
    setQuantityHandler(product, value) {
      Vue.set(this.productQuantityChanges, product.id, value);
    },
    async sortHandler(event) {
      this.sort = event;
      await this.getProducts();
    },
  }
};
</script>

<style scoped lang="sass">
.shop
  max-width: 1200px
  margin: 0 auto
  padding: 20px
</style>
