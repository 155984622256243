import Vue from 'vue';
import Vuex from 'vuex';

import createMutationsSharer from 'vuex-shared-mutations';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import ui from './module/ui';
import auth from './module/auth';
import products from './module/products';
import categories from './module/categories';
import basket from './module/basket';
import accounts from './module/accounts';
import order from './module/order';
import tecdoc from './module/tecdoc';
import admin from './module/admin';
import laximo from './module/laximo';
import wishlist from './module/wishlist';
import receivables from './module/receivables';
import returns from './module/returns';
import orderItems from './module/orderItems';
import excel from './module/excel';
import reconciliationReports from './module/reconciliationReports';
import personalCabinet from './module/personalCabinet';
import garage from './module/garage';
import socketIOPlug from './socketIOPlug';

Vue.use(Vuex);
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    ui,
    auth,
    products,
    categories,
    basket,
    accounts,
    order,
    tecdoc,
    admin,
    laximo,
    wishlist,
    receivables,
    returns,
    orderItems,
    excel,
    reconciliationReports,
    personalCabinet,
    garage,
  },
  plugins: [
    socketIOPlug,
    createMutationsSharer({
      predicate: [
        'basket/ADD_PRODUCTS_IN_BASKET',
        'basket/SET_ACTIVE_BASKET',
        'basket/SET_MULTI_BASKET',
        'basket/SET_ITEM_QUANTITY',
        'basket/SET_PRODUCT_BASKET_ITEM_ID',
        'basket/SET_MULTI_BASKET_ITEM_QUANTITY',
        'products/SET_PRODUCT_BASKET_QUANTITY'
      ]
    })
  ]
});
