<template>
  <div class="admin-menu">
    <el-tooltip class="item" effect="dark" content="Статистика" placement="right">
      <router-link class="admin-menu__block" to="/admin" exact>
        <i class="el-icon-s-data" />
      </router-link>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Пользователи" placement="right">
      <router-link class="admin-menu__block" to="/admin/users">
        <i class="el-icon-user-solid" />
      </router-link>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Логи" placement="right">
      <router-link class="admin-menu__block" to="/admin/logs">
        <i class="el-icon-menu" />
      </router-link>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Лог обновлений" placement="right">
      <router-link class="admin-menu__block" to="/admin/changelog">
        <i class="el-icon-date" />
      </router-link>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Синонимы" placement="right">
      <router-link class="admin-menu__block" to="/admin/synonyms">
        <i class="el-icon-notebook-2" />
      </router-link>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'AdminMenu',
  data() {
    return {};
  },
};
</script>

<style scoped lang="sass">
  .el-menu-vertical-demo:not(.el-menu--collapse)
    width: 200px
    min-height: 400px

  .admin-menu
    border-right: 1px solid #ebeef5
    height: calc(100vh - 42px)
    &__block
      display: flex
      align-items: center
      justify-content: center
      width: 64px
      height: 64px
      text-decoration: none
      background: rgba(#1f75a8, 0)
      transition: .2s
      &:hover
        background: rgba(#1f75a8, .2)
      i
        color: #909399
      &.router-link-active i
        color: #1f75a8
</style>
