import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  token: localStorage.getItem('user-token') || null,
  refreshToken: localStorage.getItem('user-refreshToken') || null,
});

// NOTE: устанавливает токены. ДОКА VUEX
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  SET_AUTH_DATA(state, { token, refreshToken }) {
    state.token = token;
    state.refreshToken = refreshToken;
    if (token && refreshToken) {
      localStorage.setItem('user-token', token);
      localStorage.setItem('user-refreshToken', refreshToken);
    } else {
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-refreshToken');
    }
  }
};

const actions = {
  AuthRequest({ dispatch, commit }, { email, password, guest = false }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/auth/login',
        method: 'post',
        data: {
          email,
          password,
          guest
        },
      })
        .then(({ token, refreshToken }) => {
          commit('SET_AUTH_DATA', {
            token,
            refreshToken,
          });

          commit('products/SET_SEARCH', '', { root: true });
          dispatch('categories/SetCurrentCategoryData', null, { root: true });

          resolve();
        })
        .catch(err => reject(err));
    });
  },
  AuthLogout({ commit }) {
    return new Promise((resolve) => {
      commit('SET_AUTH_DATA', {
        token: null,
        refreshToken: null,
        exp: null,
      });
      resolve();
    });
  },
  RegRequest({ dispatch, commit }, {
    email, code, name, password
  }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/auth/registration',
        method: 'post',
        data: {
          email,
          code,
          name,
          password,
        },
      })
        .then(({ token, refreshToken }) => {
          commit('SET_AUTH_DATA', {
            token,
            refreshToken,
          });

          commit('products/SET_SEARCH', '', { root: true });
          dispatch('categories/SetCurrentCategoryData', null, { root: true });
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  TokenRevoke({ dispatch, state }) {
    return new Promise((resolve) => {
      axiosInstance({
        url: '/token/rejectToken',
        method: 'post',
        data: {
          refreshToken: state.refreshToken,
        },
      })
        .then(() => {
          dispatch('AuthLogout');
          resolve();
        });
    });
  },
  TokenRefresh({ commit, state }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/token/refreshToken',
        method: 'post',
        data: {
          refreshToken: state.refreshToken,
        },
      })
        .then((response) => {
          const { token, refreshToken } = response;
          commit('SET_AUTH_DATA', {
            token,
            refreshToken,
          });

          // dispatch(AuthLogout);
          resolve(response);
        })
        .catch(err => reject(err));
    });
  },
  ForgotPass(_, { email }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/auth/restorePassword',
        method: 'post',
        data: {
          email,
        },
      })
        .then(() => {
          resolve();
        })
        .catch(err => reject(err));
    });
  },

  async HasUserPassword(_, email) {
    return axiosInstance('/auth/hasUserPassword', {
      params: {
        email
      }
    });
  }
};

/**
 * Преобразует токен пользователя в объект с данными пользователя
 * @param token {string} - Токен пользователя
 * @returns {{
    accountId: number,
    exp: number,
    iat: number,
    id: number,
    role: string,
    warehouseId: 1 | 2 | undefined,
    isAdmin: true | undefined
  } | {}}
 */
function decodeToken(token) {
  if (!token) return {};
  const base64 = token
    .split('.')[1]
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

const getters = {
  isToken: state => !!state.token,
  isRefreshToken: state => !!state.refreshToken,
  /**
   * Роль пользователя
   * @returns {string|null}
   */
  getRole: state => (state.token ? decodeToken(state.token).role : null),
  /**
   * Id пользователя
   * @returns {number|null}
   */
  getUserId: state => (state.token ? decodeToken(state.token).id : null),
  /**
   * Является ли пользователь админом
   * @returns {boolean | null}
   */
  isAdmin: state => (state.token ? decodeToken(state.token).isAdmin : false),
  /**
   * Id склада поставки товаров
   *
   * @return {(1|2)}
   */
  _warehouseId: state => (state.token ? decodeToken(state.token).warehouseId : 1),
  /**
   * Роль пользователя
   * @returns {string|null}
   */
  _role: state => (state.token ? decodeToken(state.token).role : null),
  _roleAdmin: state => (state.token ? decodeToken(state.token).role === 'admin' : null),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
