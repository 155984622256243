<template>
  <div>
    <el-table
      :data="data"
      :height="height"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="Id"
        width="80"
      />
      <el-table-column
        prop="userId"
        label="User id"
        width="65"
      />
      <el-table-column
        prop="method"
        label="Method"
        width="65"
      />
      <el-table-column
        prop="query"
        label="Query"
      />
      <el-table-column
        prop="url"
        label="Url"
      />
      <el-table-column
        label="Тематика"
        width="215"
      >
        <template v-slot="scope">
          {{ scope.row.url | subject }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        label="Дата/Время"
        width="215"
      >
        <template v-slot="scope">
          {{ scope.row.createdAt | date }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'AdminTable',
  filters: {
    date: (value) => {
      const date = new Date(value);

      return new Intl.DateTimeFormat('ru', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(date);
    },
    subject: (value) => {
      if (value.includes('/api/users/name')) return 'Получение имени';

      if (value.includes('/api/logs')) return 'Логи';

      if (value.includes('/api/productList')) return 'Список продуктов Николь';

      if (value.includes('/api/categories')) return 'Категории';

      if (value.includes('/api/baskets')) return 'Корзины';

      if (value.includes('/api/tecDoc?address=manufacturers')) return '(TecDoc) Сприсок производителей';

      if (value.includes('/api/basketItems')) return 'Добавление в корзину';

      if (value.includes('/api/tecDoc?address=tree')) return '(TecDoc) Категории';

      if (value.includes('/account/addUser')) return 'Добавление пользователя';

      if (value.includes('/api/tecDoc?address=models')) return '(TecDoc) Модели';

      if (value.includes('/api/tecDoc?address=modifications')) return '(TecDoc) Модификации';

      if (value.includes('/api/tecDoc?address=articlesSimplified')) return '(TecDoc) Товары';

      if (value.includes('/api/suppliers')) return 'Товары сторонних поставщиков';

      if (value.includes('/api/orders')) return 'История';

      if (value.includes('/api/accounts/contacts')) return 'Контакты';

      return '';
    },
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: ''
    }
  },
};
</script>

<style scoped>

</style>
