<template>
  <div :class="b()">
    <button v-if="backButton" :class="b('back-button')" @click.prevent="$emit('back-button-click')">
      <i class="el-icon-arrow-left" />
    </button>
    <div>
      <h2 :class="b('title')" class="font-bg">
        <slot />
      </h2>
      <h3 v-if="$slots.subtitle" :class="b('subtitle')" class="font-md">
        <slot name="subtitle" />
      </h3>
    </div>
    <div v-if="$slots.rightArea" :class="b('right-area')">
      <slot name="rightArea" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    backButton: {
      type: Boolean,
    }
  },
};
</script>

<style scoped lang="sass">
.page-header
  display: flex
  flex-flow: nowrap
  align-items: center
  margin-bottom: 15px
  &__back-button
    display: flex
    align-items: center
    justify-content: center
    width: 30px
    height: 30px
    margin-right: 10px
    border: 1px solid #DCDFE6
    background: white
    border-radius: 3px
    cursor: pointer
    transition: .2s
    &:hover
      border-color: #1f75a8
      background: #def0ff
  &__title
    width: 100%
    text-align: left
    align-self: flex-start
    background-color: #fff
    color: #000
    margin-top: 0
    margin-bottom: 0
    font-size: 18px
  &__subtitle
    text-align: left
    align-self: flex-start
    background-color: #fff
    color: #909399
    margin: 0
    font-weight: normal
    font-size: 14px
  &__right-area
    margin-left: auto
</style>
