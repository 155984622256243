<template>
  <div class="filter">
    <span class="filter__title">{{ filter.title }}</span>
    <el-input v-model="search" size="small" placeholder="Поиск" clearable />
    <el-checkbox-group v-model="value" class="filter__scrollarea">
      <el-checkbox :label="check" v-for="check in filtersValues" :key="`filters---${check}`">
        {{ check || 'Без бренда' }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'FilterCheckbox',
  props: {
    filter: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      value: [],
      search: '',
    };
  },
  computed: {
    /* При выборе одного элемента query становится строкой */
    /** @return {string[]} */
    queryBrand() {
      if (!this.$route.query.brand) return [];
      if (!Array.isArray(this.$route.query.brand)) return [this.$route.query.brand];
      return this.$route.query.brand;
    },
    /** @return {string[]} */
    filtersValues() {
      return [
        ...this.queryBrand || [],
        ...this.filter.values.filter(item => (this.queryBrand ? !this.queryBrand.includes(item) : true))
      ].filter((item) => {
        const string1 = item.replace(/\s/g, '').toLowerCase();
        const string2 = this.search.replace(/\s/g, '').toLowerCase();
        return string1.indexOf(string2) > -1;
      });
    }
  },
  watch: {
    value() {
      this.setFilters();
    },
    $route() {
      this.value = this.queryBrand;
    }
  },
  mounted() {
    this.value = this.queryBrand;
    this.$root.$on('resetFilters', () => {
      this.value = [];
    });
  },
  methods: {
    ...mapMutations('products', ['SET_CHANGED_FILTERS', 'SET_FILTER_CHANGE']),
    setFilters() {
      const { value, filter } = this;
      this.SET_FILTER_CHANGE(true);
      if (!value.length) {
        this.SET_CHANGED_FILTERS({
          filter,
          remove: true,
        });
        return;
      }
      this.SET_CHANGED_FILTERS({
        filter: {
          id: filter.id,
          name: filter.name,
          value,
        },
      });
    }
  }
};
</script>

<style scoped lang="sass">
  .filter
    &__title
      font-size: 11px
      font-weight: bold
    &__scrollarea
      display: flex
      flex-flow: column
      max-height: 150px
      overflow-y: scroll
      overflow-x: hidden
      margin-top: 5px
      > *
        margin-top: 5px

</style>

<style lang="sass">
  .filter
    .el-checkbox__label
      font-size: 12px

</style>
