<template>
  <div class="personal">
    <div class="personal__header">
      <div class="title">
        Синонимы
      </div>
    </div>
    <div
      class="uploader"
      @drag.prevent=""
      @dragstart.prevent=""
      @dragend.prevent=""
      @dragover.prevent=""
      @dragenter.prevent="showLabel = true"
      @dragleave.prevent=""
      @drop.prevent=""
      @mouseleave="showLabel = false"
      v-loading="synonymsLoading"
      :element-loading-text="`Загружаем ${fileName}`"
      v-if="!synonymsLoaded"
    >
      <div v-show="!synonymsLoading" class="uploader__text">
        <span class="title">Загрузите Excel файл c синонимами</span>
        <el-button @click="$refs.upload.click()">
          Выбрать файл
        </el-button>
        <span class="subtitle">или перетащите его в эту область</span>
      </div>
      <label
        v-show="showLabel"
        class="uploader__label"
        for="uploadOrder"
        @drag.prevent=""
        @dragstart.prevent=""
        @dragend.prevent=""
        @dragover.prevent=""
        @dragenter.prevent=""
        @dragleave.prevent="showLabel = false"
        @drop.prevent="sendOrderInDrop($event)"
      >
        <svg
          class="uploader__label__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 489.4 489.4"
          fill="#1f75a8"
        >
          <path d="M382.4,422.75h-79.1H282h-4.6v-106.1h34.7c8.8,0,14-10,8.8-17.2l-67.5-93.4c-4.3-6-13.2-6-17.5,0l-67.5,93.4
          c-5.2,7.2-0.1,17.2,8.8,17.2h34.7v106.1h-4.6H186H94.3c-52.5-2.9-94.3-52-94.3-105.2c0-36.7,19.9-68.7,49.4-86
          c-2.7-7.3-4.1-15.1-4.1-23.3c0-37.5,30.3-67.8,67.8-67.8c8.1,0,15.9,1.4,23.2,4.1c21.7-46,68.5-77.9,122.9-77.9
          c70.4,0.1,128.4,54,135,122.7c54.1,9.3,95.2,59.4,95.2,116.1C489.4,366.05,442.2,418.55,382.4,422.75z"
          />
        </svg>
        Кидайте, мы поймаем
      </label>
      <input id="uploadOrder" type="file" ref="upload" @change="sendOrderInInput" hidden>
    </div>
    <el-result v-else icon="success" title="Готово" sub-title="Список синонимов был успешно загружен" class="result">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="SET_SYNONYMS_LOADED(false)">
          Загрузить ещё
        </el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import validateFileExtension from 'utils/validateFileExtension';

export default {
  name: 'ViewAdminSynonyms',
  metaInfo: {
    title: 'Синонимы'
  },
  data() {
    return {
      showLabel: false,
      fileName: '',
    };
  },
  computed: {
    ...mapState('admin', ['synonymsLoading', 'synonymsLoaded']),
  },
  methods: {
    ...mapActions('admin', ['UploadSynonyms', 'CreateSynonyms']),
    ...mapMutations('admin', ['SET_SYNONYMS_LOADED']),
    sendOrderInInput() {
      this.sendOrder(this.$refs.upload.files[0]);
    },
    sendOrderInDrop(e) {
      this.sendOrder(e.dataTransfer.files[0]);
    },
    sendOrder(file) {
      this.fileName = file.name;

      if (!validateFileExtension(file.name, ['xls', 'xlsx'])) {
        this.$message.error('Загрузить можно только xls файлы');
        return;
      }

      this.showLabel = false;

      const formData = new FormData();
      formData.append('synonyms', file);

      this.$refs.upload.value = '';

      this.UploadSynonyms(formData)
        .then((synonyms) => {
          this.CreateSynonyms(synonyms).then(() => {
            this.SET_SYNONYMS_LOADED(true);
          }).catch((error) => {
            this.$message.error(error);
          });
        })
        .catch(() => {
          this.$message.error('Загрузите подходящий xls');
        });
    },
  },
};
</script>

<style scoped lang="sass" src="../admin/access/admin.sass" />
<style lang="sass" scoped>
.result
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: calc(100vh - 90px)
.uploader
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: calc(100vh - 90px)
  color: #303030
  text-align: center
  font-size: 15px
  font-weight: bold
  &::after
    content: ''
    display: block
    position: absolute
    top: 5px
    left: 5px
    right: 5px
    bottom: 5px
    border: 1px solid #ebeef5
    z-index: -1
  &__text
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    .title
      margin-bottom: 30px
    .subtitle
      font-size: 10px
      color: #4e4e4e
      margin-top: 10px
      font-weight: normal
    .after
      font-size: 12px
      margin-top: 60px
      margin-bottom: -60px
      font-weight: normal
      color: #1f75a8
      text-decoration: underline
      text-align: center
  &__label
    position: fixed
    top: 5px
    left: 5px
    right: 5px
    bottom: 5px
    border: 3px solid #ebeef5
    animation: border 1.5s infinite
    background: white
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    z-index: 1000
    &__icon
      height: 50px
</style>
