<template>
  <div class="order-list">
    <span class="order-list__breadcrumb">
      <span>Список товаров из файла Excel</span>
      <span>
        <span>Найдено <b>{{ xlsOrderLength }}</b>, показано <b>{{ xlsOrder.length }}</b></span>
        <el-button
          class="setting-button"
          size="mini"
          icon="el-icon-s-tools"
          @click="$store.commit('ui/SET_SHOW_SETTINGS', true)"
        >
          Настройки
        </el-button>
      </span>
    </span>
    <n-table
      v-model="activeRowId"
      ref="nikolTable"
      class="order-list__product-table"
      :data="xlsOrder"
      :row-class="rowClassName"
      :default-sort="sort"
      :columns="[
        { name: '', prop: 'expandProp', width: '30px', align: 'center', },
        { name: 'Артикул', prop: 'article', width: '150px', sortable: true, show: showColumns.hide, translucent: tableVisual.focus },
        { name: 'Бренд', prop: 'brand', width: '130px', sortable: true, show: showColumns.hide2, translucent: tableVisual.focus },
        { name: 'Код 1С', prop: 'externalId', width: '90px', show: showColumns.hide3, translucent: tableVisual.focus },
        { name: 'Название', prop: 'name', width: '1fr', sortable: true },
        { name: 'Цена', prop: 'price', width: '80px', align: 'right', sortable: true },
        { name: 'Кол-во', prop: 'quantity', width: '93px', align: 'center' },
        { name: 'Сумма', prop: 'sum', width: '80px', align: 'right', sortable: true },
        { name: 'Статус', prop: 'status', width: '200px', align: 'center', sortable: true },
        { name: '', prop: 'action', width: '62px', align: 'center' },
        { name: 'Удалить', prop: 'del', width: '62px', align: 'center' },
      ]"
      @sort-change="productsSort"
    >
      <template v-slot:expandProp="{ row }">
        <span v-if="row.stocks" class="expand-button" @click="changeExpand(row)">
          <i v-if="!row.expand" class="el-icon-plus" />
          <i v-else class="el-icon-minus" />
        </span>
      </template>
      <template v-slot:name="{ row }">
        <span class="name-row" @click="changeExpand(row)">
          {{ row.name }}
        </span>
      </template>
      <template v-slot:price="{ row }">
        <span v-if="row.stocks && row.stocks.length === 1">
          <priceOutput :price="normalizePriceMarkup(getPrice(row.stocks[0]))" />
        </span>
        <span v-else-if="row.stocks && row.stocks.length > 1">
          от <priceOutput :price="arrayMin(row.stocks)" />
        </span>
        <span v-else-if="row.price !== undefined">
          <priceOutput :price="normalizePriceMarkup(getPrice(row))" />
        </span>
      </template>
      <template v-slot:quantity="{ row }">
        <div v-if="!row.stocks" style="text-align: center">
          <n-input-number
            v-model="row.quantity"
            :min="+row.minShippingRate"
            :max="row.stockBalance"
            :step="+row.minShippingRate"
            :step-strictly="true"
            @change="(e) => manualFixMultiplicity(row, e)"
          />
        </div>
        <template v-else>
          <span
            :title="'до ' + getRowStocksCount(row.stocks) + ' шт.'"
            class="hide-overflow"
          >до {{ getRowStocksCount(row.stocks) }} шт.
          </span>
        </template>
      </template>
      <template v-slot:sum="{ row }">
        <price-output :price="genSum(row)" v-if="!row.stocks && row.price !== undefined" />
        <span v-else-if="row.stocks && row.stocks.length > 1">
          от <priceOutput :price="arrayMin(row.stocks)" />
        </span>
      </template>
      <template v-slot:status="{ row }">
        <template v-if="row.status === 'severalSuppliers'">
          <span
            style="border-bottom: 1px dashed #1f75a8; cursor: pointer"
            @click="SET_XLS_SUPPLIER_MODAL_PRODUCT(row)"
          >{{ state[row.status || row.state] }}</span>
        </template>
        <template v-else>
          <div>
            {{ state[row.status || row.state] }}
            <div v-if="row.state === 'notEnoughQuantity' && row.stockBalance > 0">
              (в наличии {{ row.stockBalance }})
            </div>
          </div>
        </template>
      </template>
      <template v-slot:action="{ row }">
        <el-tooltip effect="dark" content="В ожидание" v-if="row.state === 'notEnoughQuantity'">
          <el-button
            size="mini"
            @click.prevent.stop="addWishList(row)"
            icon="el-icon-alarm-clock"
          />
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :content="'Изменить количество на ' + row.stockBalance"
          v-if="row.state === 'notEnoughQuantity' && row.stockBalance > 0"
        >
          <el-button
            size="mini"
            @click="fixXlsError(row)"
            icon="el-icon-edit"
          />
        </el-tooltip>
        <el-tooltip
          effect="dark"
          content="Исправить"
          v-if="row.state === 'incorrectMultiplicity' || row.state === 'severalSuppliers'"
        >
          <el-button
            size="mini"
            @click="fixXlsError(row)"
            icon="el-icon-edit"
          />
        </el-tooltip>
      </template>
      <template v-slot:del="{ row }">
        <el-button
          :loading="loadingIndex === row.id"
          icon="el-icon-delete"
          plain
          size="mini"
          type="danger"
          @click="removeProduct(row)"
        />
      </template>
      <template v-slot:after="{ row, columns, gridTemplateColumns }">
        <template v-if="row.expand">
          <template v-for="(stock, stockIndex) in row.stocks">
            <div
              class="after-row"
              :style="{
                gridTemplateAreas: `'${columns.reduce((val, currentVal) => val + `${currentVal.prop} `, '')}'`,
                gridTemplateColumns
              }"
              :key="`stock---${stockIndex}`"
            >
              <div
                v-for="i in
                  columns.filter(
                    item => !['expandProp', 'price', 'quantity', 'sum'].includes(item.prop)
                  )
                    .length"
                :key="`emptyCol---${i}`"
              />
              <div :style="{ gridArea: 'name' }">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template v-slot:content>
                    <div style="display: flex">
                      <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;width: 100px;">
                        {{ String(stock.name) }}
                      </div>
                    </div>
                  </template>
                  <div class="cell" style="width: 115px;">
                    {{ skladId(String(stock.name || '')) }}
                  </div>
                </el-tooltip>
              </div>
              <div class="right" :style="{ gridArea: 'price' }">
                <priceOutput :price="genPrice(stock)" />
              </div>
              <div class="right" :style="{ gridArea: 'sum' }">
                <price-output :price="genSum(stock)" />
              </div>
              <div class="right" :style="{ gridArea: 'quantity' }">
                <n-input-number
                  v-model="stock.quantity"
                  :min="0"
                  :step="parseInt(stock.multiplicity)"
                  :max="stock.count - (stock.count % stock.multiplicity)"
                />
              </div>
            </div>
          </template>
        </template>
      </template>
    </n-table>
    <xls-order-list-footer :order="xlsOrder" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import XlsOrderListFooter from '@/views/xls/components/xlsOrderListFooter';
import productsSort from 'utils/productsSort';
import hide from '@/components/tables/mixins/hide';
import { normalizePriceMarkup, getPrice } from '@/utils/normalizePrice';
import calcMax from 'utils/calcMaxQuantity';
import calcStep from 'utils/calcQuantityStep';
import Vue from 'vue';

export const xlsErrorDescriptions = {
  notEnoughQuantity: 'Недостаточно количества',
  notFound: 'Товар не найден',
  severalSuppliers: 'Несколько вариантов', // выбор поставщика
  haveSeveralRows: 'Найдено несколько строк',
  unknownError: 'Неизвестная ошибка',
  excelError: 'Ошибка в Excel, проверьте правильность данных',
  incorrectMultiplicity: 'Неверная кратность товара',
  ready: 'Готов к добавлению в корзину',
};

export default {
  name: 'XlsOrderList',
  components: { XlsOrderListFooter },
  mixins: [hide],
  data() {
    return {
      state: xlsErrorDescriptions,
      loadingIndex: null,
      activeRowId: null,
      sort: { prop: 'state', order: 'ASC' }
    };
  },
  computed: {
    ...mapState('products', ['xlsOrder', 'xlsOrderLength']),
    ...mapState('ui', ['tableVisual', 'userCurrency']),
    showDelButton() {
      return this.xlsOrder.findIndex(item => item.state !== 'ready' && item.state !== 'severalSuppliers') >= 0;
    },
  },
  methods: {
    ...mapMutations('products', ['REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID', 'SET_XLS_ORDER', 'SET_XLS_SUPPLIER_MODAL_PRODUCT']),
    ...mapActions('products', ['fixXlsError']),
    ...mapActions('basket', ['XlsUploadBasket']),
    ...mapActions('wishlist', ['addToWishList']),
    removeProduct(row) {
      this.REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID(row.productId);
    },
    genPrice(row) {
      if (row.stocks) return normalizePriceMarkup(getPrice(row.stocks[0]));
      return normalizePriceMarkup(getPrice(row));
    },
    genSum(row) {
      return normalizePriceMarkup(getPrice(row) * (row.quantity || 0));
    },
    productsSort(event) {
      this.sort = event;
      this.SET_XLS_ORDER(productsSort(this.xlsOrder, event));
    },

    selectCheckout() {},

    rowClassName(row, index) {
      return {
        even: this.tableVisual.striped && index % 2 === 0,
        'xls-table-error': row.state !== 'ready' && row.state !== 'severalSuppliers'
      };
    },
    changeExpand(row) {
      if (!row.stocks) return;
      const find = item => item.article === row.article && item.guid === row.guid && item.name === row.name;
      const val = row.expand !== undefined ? !row.expand : true;
      const index = this.xlsOrder.findIndex(find);
      this.$set(this.xlsOrder[index], 'expand', val);
    },
    async addWishList(row) {
      this.addToWishList({
        productId: row.productId,
        quantity: row.quantity,
        basketName: this.activeBasketData?.name || '',
      }).then(() => {
        this.$message.success('Товар добавлен в лист ожидания');
        this.removeProduct(row);
      })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось добавить товар в лист ожидания'); });
    },
    arrayMin: arr => normalizePriceMarkup(arr.reduce((acc, p) => Math.min(acc, getPrice(p)), Infinity)),
    skladId: value => value.slice(0, 6),
    normalizePriceMarkup,
    getPrice,
    calcMax,
    calcStep,
    getRowStocksCount(stocks) {
      return stocks.reduce(
        (value, currentValue) => value
          + (typeof currentValue.count === 'string'
            ? parseInt(currentValue.count.replace(/\D/g, ''), 10)
            : currentValue.count
          ),
        0
      );
    },
    manualFixMultiplicity(item) {
      const productIndex = this.xlsOrder.findIndex(product => product.productId === item.productId);
      if (item.status === 'incorrectMultiplicity' && item.quantity % Number(item.minShippingRate) === 0) {
        item.status = 'ready';
        Vue.set(this.xlsOrder, productIndex, item);
      }
      if (item.status === 'ready' && item.quantity % Number(item.minShippingRate) !== 0) {
        item.status = 'incorrectMultiplicity';
        Vue.set(this.xlsOrder, productIndex, item);
      }
    }
  }
};
</script>

<style scoped lang="sass">
.order-list
  padding: 10px
  display: flex
  flex-flow: column
  overflow: hidden
  &__breadcrumb
    display: flex
    flex-flow: nowrap
    align-items: center
    justify-content: space-between
    font-size: 12px
    margin-bottom: 5px
  &__product-table
    flex: 1

.product
  padding: 5px
  font-size: 10px

.setting-button
  margin-left: 10px
.after-row
  display: grid
  grid-column: 1 / -1
  border-top: 1px solid #ebeef5
  &:last-child
    border-bottom: 1px solid #ebeef5
  > div
    display: flex
    align-items: center
    justify-content: center
    padding: 5px 7px
    font-size: 11px
    color: #1c1c1c
    background: white
    transition: .2s
    &:not(:first-child)
      border-left: 1px solid #ebeef5
  > div.right
    justify-content: flex-end
  &:hover > div
    background: #def0ff
</style>
