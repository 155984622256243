<template>
  <div class="personal">
    <div class="personal__header">
      <div class="title">
        Логи
      </div>
      <el-button
        size="small"
        style="margin-top: auto; margin-bottom: 4px"
        @click="dellAllRedisKeys"
      >
        Сбросить кэш
      </el-button>
    </div>

    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="Info" name="info">
          <div style="display: flex;align-items: flex-start">
            <div style="padding: 5px 5px 5px 10px;">
              <el-input
                style="width: 250px;"
                v-model="search"
                size="small"
                placeholder="Поиск по userId"
                @keydown.enter.native="filtered"
              />
            </div>
            <div style="padding: 5px;">
              <el-date-picker
                v-model="date"
                type="daterange"
                start-placeholder="Начальная дата"
                end-placeholder="Конечная дата"
                size="small"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div style="padding: 5px">
              <el-button @click="filtered" type="primary" icon="el-icon-search" size="small" />
            </div>

            <div style="padding: 5px;align-self: flex-end">
              <el-select v-model="subjectValue" clearable placeholder="Тематика" size="small" @change="setSubjectFilter">
                <el-option
                  v-for="item in subjects"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>

          <AdminTable :data="info" :height="'calc(100vh - 195px)'" />
        </el-tab-pane>
        <el-tab-pane label="Errors" name="errors">
          <div style="display: flex">
            <div style="padding: 5px 5px 5px 10px;">
              <el-input
                style="width: 250px;"
                v-model="search"
                size="small"
                placeholder="Поиск по userId"
                @keydown.enter.native="filtered"
              />
            </div>
            <div style="padding: 5px;">
              <el-date-picker
                v-model="date"
                type="daterange"
                start-placeholder="Начальная дата"
                end-placeholder="Конечная дата"
                size="small"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div style="padding: 5px">
              <el-button @click="filtered" type="primary" icon="el-icon-search" size="small" />
            </div>

            <div style="padding: 5px;align-self: flex-end">
              <el-select v-model="subjectValue" clearable placeholder="Тематика" size="small" @change="setSubjectFilter">
                <el-option
                  v-for="item in subjects"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <AdminTable :data="errors" :height="'calc(100vh - 195px)'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AdminTable from '@/views/admin/components/AdminTable';

export default {
  name: 'ViewPersonal',
  components: { AdminTable },
  metaInfo: {
    title: 'Логи'
  },
  data() {
    return {
      activeName: 'info',
      search: '',
      date: [],

      subjects: [
        {
          value: '/api/users/name',
          label: 'Получение имени'
        }, {
          value: '/api/logs',
          label: 'Логи'
        }, {
          value: '/api/productList',
          label: 'Список продуктов Николь'
        }, {
          value: '/api/categories',
          label: 'Категории'
        }, {
          value: '/api/baskets',
          label: 'Корзины'
        }, {
          value: '/api/tecDoc?address=manufacturers',
          label: '(TecDoc) Сприсок производителей'
        }, {
          value: '/api/basketItems',
          label: 'Добавление в корзину'
        }, {
          value: '/api/tecDoc?address=tree',
          label: '(TecDoc) Категории'
        }, {
          value: '/account/addUser',
          label: 'Добавление пользователя'
        }, {
          value: '/api/tecDoc?address=models',
          label: '(TecDoc) Модели'
        }, {
          value: '/api/tecDoc?address=modifications',
          label: '(TecDoc) Модификации'
        }, {
          value: '/api/tecDoc?address=articlesSimplified',
          label: '(TecDoc) Товары'
        }, {
          value: '/api/suppliers',
          label: 'Товары сторонних поставщиков'
        }, {
          value: '/api/orders',
          label: 'История'
        }, {
          value: '/api/accounts/contacts',
          label: 'Контакты'
        }
      ],
      subjectValue: ''
    };
  },
  computed: {
    ...mapGetters({
      info: 'admin/getInfo',
      errors: 'admin/getErrors',
    }),
  },
  watch: {
    $route(to) {
      if (to.name === 'view-admin') {
        this.filtered();
      }
    },
  },
  created() {
    this.filtered();
  },
  methods: {
    ...mapActions('admin', ['DellAllRedisKeys']),
    setSubjectFilter(value) {
      this.$store.commit('admin/SET_SUBJECT_FILTER', value);
    },
    filtered() {
      this.$store.dispatch('admin/GetLogs', {
        userId: this.search.length > 0 ? this.search : '',
        fromDate: this.date !== null && this.date[0] !== undefined ? this.date[0] : null,
        toDate: this.date !== null && this.date[1] !== undefined ? this.date[1] : null,
      });
    },
    async dellAllRedisKeys() {
      this.DellAllRedisKeys()
        .then(() => {
          this.$message('Кэш сброшен');
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    }
  },
};
</script>

<style scoped lang="sass" src="../admin/access/admin.sass" />
