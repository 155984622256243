<template>
  <div class="account">
    <div style="width: 500px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="Имя пользователя" prop="name" size="small">
          <el-input v-model="ruleForm.name" />
        </el-form-item>

        <el-form-item label="Email пользователя" prop="email" size="small">
          <el-input v-model="ruleForm.email" />
        </el-form-item>

        <el-button @click="$router.push('/dashboard/user-management')" style="float: left" size="small" type="danger">
          Отмена
        </el-button>

        <el-button @click="submitForm('ruleForm')" style="float: right" size="small" type="primary">
          Сохранить
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCreate',
  data() {
    return {
      ruleForm: {
        name: '',
        email: '',
      },
      rules: {
        name: [
          { required: true, message: 'Имя обязательно', trigger: 'blur' },
          {
            min: 2, max: 20, message: 'Длина имени должна быть от 2 до 20 символов', trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Введите e-mail',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Проверьте корректность введенного email',
            trigger: ['blur', 'change']
          }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch('accounts/PostMyUsers', {
            name: this.ruleForm.name,
            email: this.ruleForm.email,
          })
            .then(() => {
              this.$router.push('/dashboard/user-management');
              this.$message({
                type: 'success',
                message: 'Пользователь успешно создан',
              });
            })
            .catch(() => {
              this.$message.error('Ошибка создания пользователя');
            });
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style scoped>
  .account {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
  }

</style>
