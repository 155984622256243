<template>
  <div class="error-page">
    <div class="error-page__content">
      <img src="@/assets/img/logo.svg" width="300" alt="logo-nikol">
      <h1 class="text404">
        Упс, такой страницы не существует
      </h1>
      <br>
      <div>
        <el-row type="flex">
          <el-col>
            <router-link to="/products">
              <el-button type="primary" plain>
                Каталог товаров
              </el-button>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: 'Не найдено'
  }
};
</script>

<style scoped lang="sass">
  .error-page
    width: 100%
    height: calc(100% - 42px)
    position: absolute
    top: 42px
    left: 0
    overflow: auto
    text-align: center
    &__content
      width: 500px
      height: 250px
      margin: 20vh auto auto
    .text404
      color: #5eade1
    .el-button:hover
      background-color: #5eade1
    .el-button:focus
      background-color: #5eade1
</style>
