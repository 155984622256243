<template>
  <div class="order-return-status" :class="'order-return-status_' + type">
    {{ status }}
  </div>
</template>

<script>
export default {
  name: 'OrderReturnStatus',
  props: {
    status: {
      type: String,
      required: true
    },
  },
  computed: {
    type() {
      const states = [
        ['Создан', 'orange'],
        ['Принят в работу', 'blue'],
        ['Ожидание товара от покупателя', 'purpur'],
        ['Завершен', 'green'],
        ['Отказ', 'red'],
      ];
      const a = states.find(item => this.status === item[0]);
      return a ? a[1] : 'green';
    }
  }
};
</script>

<style scoped lang="sass">
.order-return-status
  flex-shrink: 0
  border-radius: 3px
  font-size: 11px
  text-align: center
  font-weight: bold
  width: 83px
  min-height: 23px
  display: inline-flex
  align-items: center
  justify-content: center
  text-transform: uppercase
  &_green
    color: #3D8F5F
    border: 1px solid #3D8F5F
  &_red
    color: #ff1c0a
    border: 1px solid #ff1c0a
  &_orange
    color: #FF9B0D
    border: 1px solid #FF9B0D
  &_blue
    color: #0074F7
    border: 1px solid #0074F7
  &_purpur
    color: #c000ff
    border: 1px solid #c000ff
</style>
