<template>
  <div class="personal">
    <page-header back-button @back-button-click="$router.back()">
      Слепки корзин
      <template #subtitle>
        {{ $route.query.agent }} → {{ $route.query.user }} ({{ $route.query.email }})
      </template>
    </page-header>

    <label class="basket-backup" v-for="(item, i) in history" :key="`item---${i}`">
      <el-checkbox :value="multipleSelection.includes(item.virtualBasket)" @change="change($event, item.virtualBasket)" />
      <span style="margin-left: 10px">{{ formatDate(item.createdAt) }}</span>
    </label>

    <el-button
      :disabled="!multipleSelection.length"
      :type="multipleSelection.length ? 'primary' : 'info'"
      size="small"
      style="margin-top: 10px"
      @click="downloadBasketBackups"
    >
      Скачать
    </el-button>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header/page-header';
import axiosInstance from 'api/axiosInstance';

export default {
  name: 'AdminBackupHistory',
  components: { PageHeader },
  metaInfo: {
    title: 'Слепки корзин'
  },
  data: () => ({
    multipleSelection: [],
    history: []
  }),
  async mounted() {
    await this.getBackupBaskets(this.$route.params.id);
  },
  methods: {
    async getBackupBaskets(userId) {
      this.history = await axiosInstance('/api/admin/backupBaskets', {
        params: { userId }
      });
    },
    async downloadBasketBackups() {
      if (!this.multipleSelection.length) return;
      axiosInstance('/api/admin/basketBackupToExcel', {
        params: { ids: this.multipleSelection.join() }
      })
        .then((link) => {
          setTimeout(window.open(link), 100);
        })
        .catch(err => this.$alert(err.message || err, 'Не удалось сформировать ссылку'));
    },
    change(e, basket) {
      if (e) this.multipleSelection.push(basket);
      else this.multipleSelection = this.multipleSelection.filter(select => select !== basket);
    },
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    }),
  },
};
</script>

<style lang="sass" scoped src="./access/admin.sass"></style>
