const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);
const compose = (...fns) => x => fns.reduceRight((v, f) => f(v), x);

const noop = () => {};

export {
  pipe,
  compose,
  noop,
};
