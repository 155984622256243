import Vue from 'vue';
import axiosInstance from '../../api/axiosInstance';

const apiPrefix = 'api/personalCabinet';

const state = () => ({
  /** @type {ShopProduct[]} */
  products: [],
  /** @type {ShopProduct[]} */
  allProducts: [],
  /** @type {Shop[]} */
  shops: [],
  shopsLoading: true,
  productsLoading: true,
  allProductsLoading: true,
});

const mutations = {
  SET_SHOPS(state, shops) {
    state.shops = shops;
  },
  SET_SHOPS_LOADING(state, loadingState) {
    state.shopsLoading = loadingState;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_ALL_PRODUCTS(state, products) {
    state.allProducts = products;
  },
  ADD_ALL_PRODUCTS(state, products) {
    state.allProducts.push(...products);
  },
  SET_PRODUCT(state, { id, productData }) {
    const productIndex = state.products.findIndex(product => product.id === id);
    if (productIndex === -1) {
      state.products.push(productData);
    }

    Vue.set(state.products, productIndex, productData);
  },
  SET_PRODUCTS_LOADING(state, loadingState) {
    state.productsLoading = loadingState;
  },
  SET_ALL_PRODUCTS_LOADING(state, loadingState) {
    state.allProductsLoading = loadingState;
  },
};
const actions = {
  /**
   * Создать товар
   * @param _ {Object<context>}
   * @param {string} name Название товара
   * @param {string} externalId Код стороннего каталога при наличии
   * @param {string} article Артикль товара
   * @param {string} brand Бренд товара
   * @param {string} OEM OEM товара
   * @param {number} minShippingRate Минимальная кратность товара
   * @returns {Promise<any>}
   */
  async createProduct({ commit }, {
    name,
    externalId,
    article,
    brand,
    OEM,
    minShippingRate,
  }) {
    return axiosInstance.post(`${apiPrefix}/products`, {
      name,
      externalId,
      article,
      brand,
      OEM,
      minShippingRate,
    });
  },
  /**
   * Возвращает список товаров пользователя
   * @param commit
   * @param limit Количество товаров
   * @param offset Отступ
   * @param shopId
   * @param {string} sortField
   * @param {string} sortOrder
   * @returns {Promise<any>}
   */
  async getProducts({ commit }, {
    limit = 50, offset = 0, shopId,
    sortField = undefined, sortOrder = undefined,
  } = {}) {
    commit('SET_PRODUCTS_LOADING', true);
    commit('SET_PRODUCTS', []);

    if (sortField === 'name') {
      sortField = 'productName';
    }

    return axiosInstance(`${apiPrefix}/shops/${shopId}/productsWithStockbalance`, {
      params: {
        limit,
        offset,
        shopId,
        sortField,
        sortOrder,
      }
    })
      .then((response) => {
        commit('SET_PRODUCTS', response);
        return response;
      }).finally(() => {
        commit('SET_PRODUCTS_LOADING', false);
      });
  },
  async getAllProducts({ commit }, {
    limit = 50, offset = 0,
    sortField = undefined, sortOrder = undefined,
    searchQuery = undefined,
  } = {}) {
    commit('SET_ALL_PRODUCTS_LOADING', true);
    commit('SET_ALL_PRODUCTS', []);

    return axiosInstance(`${apiPrefix}/products`, {
      params: {
        limit,
        offset,
        sortField,
        sortOrder,
        q: searchQuery || undefined,
      }
    })
      .then((response) => {
        if (offset === 0) {
          commit('SET_ALL_PRODUCTS', response);
        } else {
          commit('ADD_ALL_PRODUCTS', response);
        }
        return response;
      }).finally(() => {
        commit('SET_ALL_PRODUCTS_LOADING', false);
      });
  },
  /**
   * Получить данные по товару
   * @param _ {Object<context>}
   * @param {number} productId Идентификатор товара
   * @returns {Promise<ShopProduct>}
   */
  async getProduct({ commit }, productId) {
    return axiosInstance(`${apiPrefix}/products/${productId}`)
      .then((response) => {
        commit('SET_PRODUCT', {
          id: productId,
          productData: response,
        });
      });
  },
  /**
   * Обновить данные по товару
   * @param _ {Object<context>}
   * @param {number} productId Идентификатор товара
   * @param {string} name Название товара
   * @param {string} externalId Код стороннего каталога при наличии
   * @param {string} article Артикль товара
   * @param {string} brand Бренд товара
   * @param {string} OEM OEM товара
   * @param {number} minShippingRate Минимальная кратность товара
   * @returns {Promise<any>}
   */
  async editProduct(_, {
    productId,
    name,
    externalId,
    article,
    brand,
    OEM,
    minShippingRate,
  }) {
    return axiosInstance.patch(`${apiPrefix}/products/${productId}`, {
      name,
      externalId,
      article,
      brand,
      OEM,
      minShippingRate,
    })
      .then(response => response);
  },
  /**
   * Получить список магазинов
   *
   * @param commit
   * @param {number} limit Количество товаров
   * @param {number} offset Отступ
   * @returns {Promise<Shop[]>} Возвращает список магазинов привязанных к аккаунту
   */
  async getShops({ commit }, { limit = 20, offset = 0 } = {}) {
    commit('SET_SHOPS_LOADING', true);
    return axiosInstance(`${apiPrefix}/shops`, {
      params: {
        limit,
        offset
      }
    })
      .then((response) => {
        commit('SET_SHOPS', response);
        return response;
      }).finally(() => {
        commit('SET_SHOPS_LOADING', false);
      });
  },
  /**
   * Создать магазин
   *
   * @param _ {Object<context>}
   * @param {string} name Название магазина
   * @returns {Promise<Shop>} Возвращает созданный магазин
   */
  async createShop(_, name) {
    return axiosInstance.post(`${apiPrefix}/shops`, {
      name,
    })
      .then(response => response);
  },
  /**
   * Получить данные по магазину
   *
   * @param _ {Object<context>}
   * @param {number} shopId Идентификатор магазина
   * @returns {Promise<Shop>} Возвращает данные по магазину
   */
  async getShop(_, shopId) {
    return axiosInstance(`${apiPrefix}/shops/${shopId}`);
  },
  /**
   * Обновить данные по магазину
   *
   * @param _ {Object<context>}
   * @param { number }shopId Идентификатор магазина
   * @param { string } name Название магазина
   * @returns {Promise<Shop>} Возвращает данные по магазину
   */
  async editShop(_, { shopId, name }) {
    return axiosInstance.patch(`${apiPrefix}/shops/${shopId}`, {
      name
    })
      .then(response => response);
  },
  /**
   * Удалить магазин
   *
   * @param _ {Object<context>}
   * @param {number} shopId Идентификатор магазина
   * @returns {Promise<Shop>} Возвращает данные по магазину
   */
  async deleteShop(_, shopId) {
    return axiosInstance.delete(`${apiPrefix}/shops/${shopId}`)
      .then(response => response);
  },

  /**
   * Изменить остаток товара
   *
   * Положительное число прибавляет к остатку. Отрицательное уменьшает. 0 зануляет остаток
   *
   * @param _ {Object<context>}
   * @param {number} productId Id продукта
   * @param {number} stockBalance Количество товара к добавлению/уменьшению
   * @param {number} shopId Идентификатор магазина
   * @returns {Promise<any>}
   */
  async setStockBalance(_, { productId, stockBalance, shopId }) {
    return axiosInstance.post(`${apiPrefix}/stockBalance`, {
      productId,
      stockBalance,
      shopId
    })
      .then(response => response);
  },
  /**
   * Обновить остатки исходя из информации заказа Николь
   *
   * @param _ {Object<context>}
   * @param {number[]} orderProductIds Массив товаров
   * @param {number} shopId Идентификатор магазина
   * @returns {Promise<any>}
   */
  async setStockBalanceFromOrders(_, { orderProductIds, shopId }) {
    return axiosInstance.post(`${apiPrefix}/stockBalance/fromOrders`, {
      orderProductIds,
      shopId
    })
      .then(response => response);
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
