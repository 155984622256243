<template>
  <div class="xls-page">
    <el-container>
      <app-aside />
      <el-main class="container">
        <xls-uploader v-show="!xlsOrder.length" />
        <xls-order-list v-show="xlsOrder.length" />
        <basket-aside />
      </el-main>
    </el-container>
    <settings-dialog />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppAside from '@/components/app-tree/app-aside';
import BasketAside from '@/components/basket-aside/basketAside';
import SettingsDialog from '@/components/product-table/layout/settingsDialog';
import xlsOrderList from './components/xlsOrderList';
import xlsUploader from './components/xlsUploader';


export default {
  name: 'Xls',
  metaInfo: {
    title: 'Загрузка Excel'
  },
  components: {
    SettingsDialog,
    BasketAside,
    xlsOrderList,
    AppAside,
    xlsUploader,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('products', ['xlsOrder', 'loading']),
  },
};
</script>

<style scoped lang="sass">
.container
  display: flex
  flex-flow: column
  height: calc(100vh - 42px)
  overflow: hidden

.basket-card
  background-color: #fff
  border-top: 1px solid #EBEEF5
  position: fixed
  bottom: 0
  right: 0
  z-index: 99
  height: 270px
  &__body
    float: right
    display: flex
    flex-direction: column

.color-black
  color: black
</style>
