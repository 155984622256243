import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  /** @type IOrderHistoryItem[] */
  orderItems: []
});

const mutations = {
  SET_ORDER_ITEMS(state, orderItems) {
    state.orderItems = orderItems;
  },
  ADD_ORDER_ITEMS(state, orderItems) {
    state.orderItems.push(...orderItems);
  },
};

const actions = {
  /**
   * Получение отчёта по закупам
   * @param {function(mutation: string, payload: any)} commit
   * @param {number} max
   * @param {number} offset
   * @param {'productname'|'createdAt'} sortField
   * @param sortOrder
   * @param {'productname', 'productId'} groupField
   * @param {string} fieldsToAggregate Строки, через запятую. Формат quantity-sum
   * @param from
   * @param to
   * @param groupByDate
   * @return {Promise<OrderItem[]>}
   */
  async getOrderItems({ commit }, {
    max = undefined,
    offset = undefined,
    sortField = undefined,
    sortOrder = undefined,
    groupField = undefined,
    fieldsToAggregate = undefined,
    from = undefined,
    to = undefined,
    groupByDate = undefined,
  } = {}) {
    return new Promise(async (resolve, reject) => {
      await axiosInstance('/api/orderItems', {
        params: {
          max,
          offset,
          sortField,
          sortOrder,
          groupField,
          fieldsToAggregate,
          from,
          to,
          groupByDate,
          attributes: ['productId', 'productName', 'productPrice', 'createdAt', 'quantity']
        }
      })
        .then((response) => {
          if (offset === 0) {
            commit('SET_ORDER_ITEMS', response);
          } else {
            commit('ADD_ORDER_ITEMS', response);
          }
          resolve(response);
        }).catch(reject);
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
