<template>
  <transition
    name="showWishlistModal"
    appear
  >
    <div class="wishlist-modal">
      <el-button
        class="wishlist-modal__close"
        @click="closeModal"
        icon="el-icon-close"
        size="mini"
      />
      <div class="wishlist-modal__title">
        Список обновлён
      </div>
      <div class="wishlist-modal__subtitle font-md">
        У товаров в листе ожидания изменилось количество остатков
      </div>
      <div class="wishlist-modal__content">
        <n-table
          :data="productsInStock"
          :loading="loading"
          :table-loading="loading && !productsInStock.length"
          :expected-length="3"
          :columns="[
            { name: 'Артикул', prop: 'article', width: '100px', translucent: true },
            { name: 'Бренд', prop: 'brand', width: '130px', translucent: true },
            { prop: 'name', name: 'Название' },
            { prop: 'quantity', name: 'Кол-во', width: '93px', align: 'center', translucent: true },
            { prop: 'stockBalance', name: 'Остаток', width: '60px', align: 'center', translucent: true },
            { prop: 'createdAt', name: 'Добавлено', width: '110px', translucent: true },
          ]"
          @sort-change="sort($event)"
          size="small"
        >
          <template v-slot:createdAt="{ row }">
            {{ formatDate(row.createdAt) }}
          </template>
          <template v-slot:quantity="scope">
            <n-input-number
              v-model="scope.row.quantity"
              :min="calcStep(scope.row)"
              :max="calcMax(scope.row)"
              :step="calcStep(scope.row)"
              @change="changeQuantity(scope.row)"
            />
          </template>
        </n-table>
      </div>
      <div class="wishlist-modal__footer">
        <el-button @click="unsubscribe" size="mini" class="wishlist-modal__unsubscribe-button">
          Не напоминать об этих товарах
        </el-button>
        <span class="wishlist-modal__link" @click="open">
          Перейти в лист ожидания
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'WishlistModal',
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('wishlist', ['hasNotification', 'productsInStock']),
  },
  methods: {
    ...mapActions('wishlist', ['closeModal', 'changeProductNotificationState', 'changeProductQuantity']),
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    }),
    open() {
      this.$router.push('/wishlist');
      this.closeModal();
    },
    unsubscribe() {
      this.productsInStock.filter(row => row.notification).forEach((wishlistItem) => {
        this.changeProductNotificationState({
          productId: wishlistItem.id,
          enabled: false
        });
      });
      this.$message.success('Уведомление скрыто');
      this.closeModal();
    },
    changeQuantity(row) {
      this.changeProductQuantity({
        productId: row.id,
        quantity: row.quantity
      });
    },
    calcStep: row => +row.minShippingRate || 1,
    calcMax(row) {
      let count = 9999;
      if (row.supplier && typeof row.supplier === 'string') count = JSON.parse(row.supplier).stocks.count;
      if (row.supplier && typeof row.supplier === 'object') count = row.supplier.stocks.count;
      if (row.warehouseId === 1) count = row.stockBalance;
      if (row.warehouseId === 2) count = row.stockBalanceRegionalWarehouse;
      return count - (count % this.calcStep(row));
    },
  }
};
</script>

<style lang="sass">
.wishlist-modal .n-table__body
  max-height: 110px
</style>
<style scoped lang="sass">
  .showWishlistModal-enter,
  .showWishlistModal-leave-to
    opacity: 0
    transform: translateY(-5px)
  .showWishlistModal-leave,
  .showWishlistModal-enter-to
    opacity: 1
    transform: translateY(0)
  .showWishlistModal-enter-active,
  .showWishlistModal-leave-active
    transition: all .3s
  .n-table__body
    max-height: 20px
  .wishlist-modal
    position: absolute
    top: 100%
    color: #000
    background: #fff
    border: 1px solid #ebeef5
    border-radius: 6px
    z-index: 22
    padding-top: 10px
    box-shadow: 0 0 6px rgba(0, 0, 34, 0.15)
    &:before
      content: ''
      display: block
      position: absolute
      top: -6px
      left: 50px
      width: 0
      height: 0
      border-left: 6px solid transparent
      border-right: 6px solid transparent
      border-bottom: 6px solid #fff
    &__title
      padding: 0 10px
      font-size: 12px
      font-weight: bold
    &__subtitle
      color: #909399
      padding: 0 10px
    &__close
      position: absolute
      right: 10px
      top: 10px
    &__content
      padding: 10px 10px 0
    &__footer
      display: flex
      justify-content: space-between
      align-items: center
      font-size: 12px
    &__unsubscribe-button
      margin-left: 10px
    &__link
      display: inline-block
      color: #1f75a8
      cursor: pointer
      padding: 10px
      text-decoration: none
      transition: 0.2s
      &:hover
        background: radial-gradient(circle at bottom right, #ecf5ff, white 50%)
    &__close-dropdown-item
      padding: 0 10px !important
</style>
