<template>
  <div class="new-site">
    <div>
      <h3>Уважаемые клиенты!</h3>
      <p>
        С 01.08.21 онлайн заказ на старом сайте будет заменен на новый, с расширенным функционалом.
        Раздел «Иномарки» будет объединен с новым интерфейсом и доступен через внутренние каталоги.
      </p>
      <p>
        Обратите внимание, что товары в корзинах перенесены не будут!
        Пожалуйста, оформите заказы до 31.07 включительно, чтобы не потерять состав заявки.
      </p>
      <p v-if="!$store.getters['auth/isToken']">
        Для ознакомления с интерфейсом сайта,
        вы можете нажать на кнопку «Войти как гость» - так Вы сможете протестировать все функции сайта до регистрации,
        но для оформления заказа Вам нужна учетная запись.
      </p>
      <p v-if="!$store.getters['auth/isToken']">
        Для создания новой учетной записи на
        <a class="new-site__link" href="https://online.nikol.biz/">online.nikol.biz</a>,
        а также при возникновении вопросов, обратитесь к своему менеджеру.
        Благодарим за сотрудничество!
      </p>
      <p v-else>
        По всем возникающим вопросам и работе с сайтом вы можете обратиться к своему менеджеру.
        Благодарим за сотрудничество!
      </p>
      <el-button @click="authRequestGuest" v-if="!$store.getters['auth/isToken']">
        Войти как гость
      </el-button>
      <div class="new-site__links">
        <router-link v-if="!$store.getters['auth/isToken']" to="/auth/login" class="new-site__link">
          Уже есть аккаунт?
        </router-link>
        <router-link v-if="$store.getters['auth/isToken']" to="/products" class="new-site__link">
          Перейти к заказу
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewSite',
  methods: {
    async authRequestGuest() {
      await this.$store.dispatch(
        'auth/AuthRequest',
        {
          guest: true
        },
      ).then(() => {
        this.$router.push('/products');
        this.$store.commit('accounts/SET_EMAIL', 'testuser@email.com');
      }).catch(() => {
        this.$message.error('Произошла ошибка, попробуйте позже');
      });
    }
  }
};
</script>

<style scoped lang="sass">
.new-site
  display: flex
  align-items: center
  justify-content: center
  height: calc(100vh - 170px)
  div
    max-width: 600px
  &__links
    display: flex
    flex-flow: column
    margin-top: 20px
  &__link
    color: #1f75a8
</style>
