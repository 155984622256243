<template>
  <div class="add-to-cart" :class="{ 'add-to-cart_disabled': disabled, 'add-to-cart_loading': loading }">
    <n-input-number
      v-model="computedValue"
      :border="false"
      :size="size"
      :step="step"
      :max="max"
      :min="min"
      :disabled="disabled"
    />
    <button
      class="add-to-cart__button"
      :class="{ 'add-to-cart__button_disabled': disabled, 'add-to-cart__button_loading': loading }"
      :disabled="disabled"
      @click.prevent="addToCart"
    >
      <span class="el-icon-loading" v-if="loading" />
      <span class="el-icon-shopping-cart-2" v-else />
    </button>
  </div>
</template>

<script>
import NInputNumber from '@/ui-kit/components/InputNumber/NInputNumber';
import { size } from '@/ui-kit/props';

export default {
  name: 'AddToCart',
  components: {
    NInputNumber,
  },
  props: {
    size,
    step: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    addToCart() {
      this.$emit('addToCart', this.value);
    }
  }
};
</script>

<style scoped lang="sass">
@keyframes loading
  0%
    opacity: .7
  50%
    opacity: .4
  100%
    opacity: .7

.add-to-cart
  display: inline-flex
  flex-flow: nowrap
  background: #52A3D3
  padding: 1px
  border-radius: 4px
  transition: .2s
  &_loading
    animation: 1s loading infinite
    cursor: progress
    pointer-events: none
  &:hover
    background: #1f75a8
  &__button
    border: none
    background: none
    color: white
    width: 23px
    cursor: pointer
    padding: 0
    &_loading
      cursor: progress
</style>
