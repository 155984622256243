<template>
  <div class="personal">
    <page-header>
      Статистика
    </page-header>
    <div>
      <el-row :gutter="20" v-if="healthcheck" v-loading="healthcheckLoading">
        <el-col :span="4">
          <div>
            <el-statistic :value="healthcheck.redisResponse" title="Ответ от Redis" suffix="мс">
              <template slot="prefix">
                <i class="el-icon-success" style="color: green" v-if="healthcheck.redisResponse" />
                <i class="el-icon-error" style="color: red" v-else />
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic :value="healthcheck.product ? 'OK' : 'Error'" title="Состояние БД">
              <template slot="prefix">
                <i class="el-icon-success" style="color: green" v-if="healthcheck.product" />
                <i class="el-icon-error" style="color: red" v-else />
              </template>
            </el-statistic>
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic :value="healthcheck.order || 0" title="Количество заказов в очереди на обработку для 1с" />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic :value="Number(healthcheck.checkProblemInOrdersCount || 0)" title="Заказов с ошибками" />
          </div>
        </el-col>
        <el-col :span="4">
          <div>
            <el-statistic :value="healthcheck.usersOnline || 0" title="Пользователей онлайн" />
          </div>
        </el-col>
      </el-row>
      <h2>Заказы</h2>
      <div style="display:flex;gap:10px">
        <el-select v-model="period" placeholder="Выбрать статистику за период" default-first-option>
          <el-option
            label="Год"
            value="year"
          />
          <el-option
            label="Месяц"
            value="month"
          />
          <el-option
            label="День недели"
            value="dayOfWeek"
          />
          <el-option
            label="Час"
            value="hour"
          />
        </el-select>
        <template v-if="period === 'dayOfWeek'">
          <el-date-picker
            v-model="dateFilter"
            type="daterange"
            range-separator=""
            start-placeholder="От даты"
            end-placeholder="до даты"
            :clearable="false"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </template>
      </div>

      <Bar v-if="period === 'year'" :chart-data="chartData" :chart-options="chartOptions" v-loading="ordersLoading" />
      <LineChartGenerator v-else :chart-data="chartData" :chart-options="chartOptions" v-loading="ordersLoading" />
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import PageHeader from '@/components/page-header/page-header.vue';
import { Line as LineChartGenerator, Bar } from 'vue-chartjs/legacy';

// noinspection ES6UnusedImports
// eslint-disable-next-line
import Chart from 'chart.js/auto';


export default {
  name: 'ViewAdminUsers',
  components: { PageHeader, LineChartGenerator, Bar },
  metaInfo: {
    title: 'Панель администрирования'
  },
  data() {
    return {
      interval: null,
      period: 'month',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      dateFilter: [],
    };
  },
  computed: {
    ...mapState('admin', ['healthcheckLoading', 'healthcheck', 'ordersLoading', 'orders']),
    chartData() {
      let labels = [];
      let data = this.orders.map(orderData => orderData.count);
      const weekdays = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ];
      if (this.period === 'hour') {
        /**
         *
         * @param {string} timeString
         * @param {number} startHour
         * @param {number} startMinute
         * @param {number} endHour
         * @param {number} endMinute
         */
        const timeFilter = (timeString, startHour, startMinute, endHour, endMinute) => {
          const time = new Date(timeString);
          return time.getHours() >= startHour
            && time.getMinutes() >= startMinute
            && time.getHours() <= endHour
            && time.getMinutes() <= endMinute;
        };
        labels = [
          '00:01-08:59',
          '09:00-10:00',
          '10:01-11:00',
          '11:01-12:00',
          '12:01-13:00',
          '13:01-14:00',
          '14:01-15:00',
          '15:01-16:00',
          '16:01-17:00',
          '17:01-18:00',
          '18:01-00:00',
        ];
        data = [
          this.orders.filter(orderData => timeFilter(orderData.hour, 0, 0, 8, 59)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 9, 0, 10, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 10, 0, 11, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 11, 0, 12, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 12, 0, 13, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 13, 0, 14, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 14, 0, 15, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 15, 0, 16, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 16, 0, 17, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 17, 0, 18, 0)).reduce((sum, current) => sum + Number(current.count), 0),
          this.orders.filter(orderData => timeFilter(orderData.hour, 18, 0, 23, 59)).reduce((sum, current) => sum + Number(current.count), 0),
        ];
      } else if (this.period === 'month') {
        labels = this.orders.map(orderData => new Date(orderData.month).toLocaleString('ru', {
          year: 'numeric',
          month: 'long',
        }));
      } else if (this.period === 'year') {
        labels = this.orders.map(orderData => new Date(orderData.year).toLocaleString('ru', {
          year: 'numeric',
        }));
      } else if (this.period === 'dayOfWeek') {
        labels = weekdays;
        data = [
          this.orders.find(orderData => Number(orderData.day) === 1)?.count,
          this.orders.find(orderData => Number(orderData.day) === 2)?.count,
          this.orders.find(orderData => Number(orderData.day) === 3)?.count,
          this.orders.find(orderData => Number(orderData.day) === 4)?.count,
          this.orders.find(orderData => Number(orderData.day) === 5)?.count,
          this.orders.find(orderData => Number(orderData.day) === 6)?.count,
          this.orders.find(orderData => Number(orderData.day) === 7)?.count,
        ];
      }
      return {
        labels,
        datasets: [
          {
            label: 'Заказы',
            backgroundColor: '#f87979',
            data,
          }
        ]
      };
    }
  },
  watch: {
    async period() {
      await this.getData();
    },
    async dateFilter() {
      await this.getData();
    },
  },
  async mounted() {
    await this.getData();

    this.interval = setInterval(async () => {
      await this.getData();
    }, 10000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions('admin', ['getHealthCheck', 'getOrders']),
    async getData() {
      await this.getHealthCheck().catch(() => {
        this.$message.error('Ошибка загрузки статистики информации о статусе сайта');
      });
      await this.getOrders({
        type: this.period,
        from: this.period === 'dayOfWeek' ? (this.dateFilter[0] || null) : undefined,
        to: this.period === 'dayOfWeek' ? (this.dateFilter[1] || null) : undefined,
      }).catch(() => {
        this.$message.error('Ошибка загрузки статистики по заказам');
      });
    },
  },
};
</script>

<style scoped lang="sass" src="../admin/access/admin.sass" />
