<template>
  <div v-loading="loading" :class="b()" v-if="basketItems.length">
    <div :class="b('header')">
      <div class="title-wrap">
        <el-checkbox :value="selected.includes(basket.id)" @change="selectionHandler" />
        <h2>{{ basket.name }}</h2>
      </div>
    </div>
    <div :class="b('body')">
      <n-table
        :data="basketItems"
        :columns="[
          { name: 'Артикул', prop: 'article', width: '100px' },
          { name: 'Название', prop: 'name', width: 'minmax(200px, 1fr)' },
          { name: 'Причина возврата', prop: 'reason', width: 'minmax(100px, 1fr)' },
          { name: 'Цена', prop: 'price', width: '80px', align: 'right' },
          { name: 'Кол-во', prop: 'quantity', width: '93px', align: 'center' },
          { name: 'Сумма', prop: 'sum', width: '76px', align: 'right' },
          { name: 'Убрать', prop: 'del', width: '56px', align: 'center', hideTitleOnMobile: true },
        ]"
      >
        <template v-slot:quantity="scope">
          <n-input-number
            v-model="scope.row.quantity"
            @change="inputPutQuantity(scope.row.id, $event, scope.row)"
          />
        </template>
        <template v-slot:article="{row}">
          <template v-if="row.supplier">
            {{ JSON.parse(row.supplier).article }}
          </template>
          <template v-else>
            {{ row.article }}
          </template>
        </template>
        <template v-slot:name="{row}">
          <template v-if="row.supplier">
            {{ JSON.parse(row.supplier).name }}
          </template>
          <template v-else>
            {{ row.name }}
          </template>
        </template>
        <template v-slot:del="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="deleteProduct(scope.row)"
          />
        </template>
        <template v-slot:price="scope">
          <priceOutput :price="normalizePriceMarkup(getPrice(scope.row))" />
        </template>
        <template v-slot:sum="scope">
          <priceOutput :price="normalizePriceMarkup(getPrice(scope.row) * scope.row.quantity)" />
        </template>
      </n-table>
      <div :class="b('footer')">
        <price-output
          :price="normalizePrice(totalPrice)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getPrice, normalizePrice, normalizePriceMarkup } from '@/utils/normalizePrice';
import {
  mapMutations, mapActions, mapState, mapGetters
} from 'vuex';
import debounce from 'utils/debounce';

export default {
  name: 'ReturnOrderCreateBasketBlock',
  model: {
    prop: 'multipleSelection',
    event: 'select',
  },
  props: {
    /** @type ReturnsBasket|Object */
    basket: {
      type: Object,
      required: true
    },
    selected: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    loading: false,
    /** @type ReturnsBasketItem[] */
    basketItems: []
  }),
  computed: {
    ...mapGetters('auth', ['_roleAdmin']),
    ...mapState('ui', ['windowSize']),
    ...mapState('returns', ['basketId']),
    totalPrice() {
      return this.basketItems.reduce((sum, current) => sum + getPrice(current) * current.quantity, 0);
    },
  },
  async mounted() {
    this.basketItems = await this.GetReturnProductsBasket(this.basket.id, false);
  },
  methods: {
    normalizePriceMarkup,
    normalizePrice,
    getPrice,
    ...mapActions('returns', ['ChangeItemInReturnProductsBasket', 'DeleteItemFromReturnProductsBasket', 'GetReturnProductsBasket']),
    ...mapMutations('returns', ['DELETE_RETURN_BASKET_ITEM']),
    inputPutQuantity: debounce(async function inputPutQuantityFunc(basketItemId, quantity) {
      await this.ChangeItemInReturnProductsBasket({
        returnProductsBasketItemId: basketItemId,
        quantity
      });
    }),
    deleteProduct(product) {
      this.$confirm('Вы уверены, что хотите удалить товар?', 'Удаление', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          this.DeleteItemFromReturnProductsBasket(product.id).then(() => {
            this.DELETE_RETURN_BASKET_ITEM(product.id);
            this.$message({
              type: 'success',
              message: 'Товар удален',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
    selectionHandler(isChecked) {
      if (isChecked) {
        this.$emit('update:selected', [...this.selected, this.basket.id]);
      } else {
        this.$emit('update:selected', [...this.selected].filter(item => item !== this.basket.id));
      }
    },
  }
};
</script>

<style scoped lang="sass">
  h3
    font-size: 12px
    color: #636363
    font-weight: 400
    margin: 0
  p
    font-size: 12px
    color: #282828
    margin: 2px 0 15px 0
  .return-order-create-basket-block
    position: relative
    padding: 10px 20px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    margin-bottom: 10px
    &__header
      display: flex
      flex-flow: nowrap
      align-items: center
      padding-right: 30px
      > *
        position: relative
        z-index: 2
      @media (max-width: 700px)
        flex-flow: column
        align-items: flex-start
        padding-right: 0
      .title-wrap
        display: flex
        flex-flow: nowrap
        align-items: center
        margin-right: 30px
        min-width: 200px
        @media (max-width: 700px)
          margin-right: 0
          margin-bottom: 5px
      h2
        margin-left: 10px
        font-size: 14px
        color: #636363
    &__data
      display: flex
      flex-flow: column
      font-size: 12px
      color: #282828
      margin-right: 15px
      opacity: .8
      @media (max-width: 700px)
        margin-right: 0
        margin-bottom: 5px
      span
        font-size: 10px
        color: #636363
    &__body
      position: relative
      padding-top: 10px
      padding-bottom: 10px
      z-index: 2
    &__footer
      display: flex
      flex-flow: nowrap
      align-items: center
      justify-content: flex-end
      margin-top: 10px
</style>
