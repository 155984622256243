<template>
  <div
    v-if="needRefresh"
    class="update"
  >
    <div class="update__title">
      Сайт был обновлен
    </div>
    <p>Пожалуйста, перезагрузите страницу для корректной работы</p>

    <button
      class="update__button"
      @click="updateServiceWorker()"
    >
      Перезагрузить
    </button>
  </div>
</template>

<script>
import useRegisterSW from '@/utils/useRegisterSW';

export default {
  name: 'AppUpdate',
  mixins: [useRegisterSW],
  watch:{
    $route (to, from){
      if (this.needRefresh) {
        this.updateServiceWorker()
      }
    },
    needRefresh(value) {
      if (value) {
        this.updateServiceWorker();
      }
    }
  }
};
</script>

<style scoped lang="sass">
.update
  position: fixed
  display: flex
  flex-direction: column
  bottom: 20px
  right: 20px
  background: #fff linear-gradient(45deg, #f2f3f6, #fafafc)
  border: 1px solid #d5d7de
  box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
  padding: 20px
  border-radius: 6px
  font-size: 14px
  z-index: 1000
  max-width: 350px
  &__title
    font-weight: bold
  &__button
    background: #e5e9f2
    color: #4b89b2
    padding: 10px
    cursor: pointer
    font-weight: bold
    border: 1px solid #d5d7de
    &:hover
      background-color: #d3dce6
</style>
