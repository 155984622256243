<template>
  <el-dialog title="Выбор поставщика" :visible="open" @close="SET_XLS_SUPPLIER_MODAL_PRODUCT(null)">
    <n-table
      v-model="activeRowId"
      v-if="product"
      :data="product.suppliers"
      :columns="[
        { name: 'Поставщик', prop: 'supplier', width: '100px', },
        { name: 'Артикул', prop: 'article', width: '150px', },
        { name: 'Бренд', prop: 'brand', width: '130px', },
        { name: 'Название', prop: 'name', width: '1fr' },
        { name: 'Цена', prop: 'price', width: '80px', align: 'right'},
        { name: 'Кол-во', prop: 'quantity', width: '93px', align: 'right' },
      ]"
      @click-on-row="selectSupplier"
    >
      <template v-slot:quantity="{ row }">
        <div v-if="!row.stocks" style="text-align: center">
          <n-input-number
            v-if="!!row.maxRate"
            v-model="row.quantity"
            :min="0"
            :max="row.maxRate"
            :step="+row.minShippingRate"
            :step-strictly="true"
          />
        </div>
        <template v-else>
          <span
            :title="'до ' + getRowStocksCount(row.stocks) + ' шт.'"
            class="hide-overflow"
          >до {{ getRowStocksCount(row.stocks) }} шт.
          </span>
        </template>
      </template>
      <template v-slot:price="{ row }">
        <span v-if="row.stocks && row.stocks.length === 1">
          <priceOutput :price="normalizePriceMarkup(getPrice(row.stocks[0]))" />
        </span>
        <span v-else-if="row.stocks && row.stocks.length > 1">
          от <priceOutput :price="arrayMin(row.stocks)" />
        </span>
        <span v-else-if="row.price !== undefined">
          <priceOutput :price="normalizePriceMarkup(getPrice(row))" />
        </span>
      </template>
    </n-table>
  </el-dialog>
</template>

<script>
import { getPrice, normalizePriceMarkup } from 'utils/normalizePrice';
import { mapMutations } from 'vuex';

export default {
  name: 'SuppliersModal',
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false
    },
    product: {
      type: Object,
    }
  },
  data() {
    return {
      activeRowId: null,
    };
  },
  methods: {
    ...mapMutations('products', ['SET_XLS_SUPPLIER_MODAL_PRODUCT', 'UPDATE_XLS_ITEM_BY_PRODUCT_ID']),
    getRowStocksCount(stocks) {
      return stocks.reduce(
        (value, currentValue) => value
          + (typeof currentValue.count === 'string'
            ? parseInt(currentValue.count.replace(/\D/g, ''), 10)
            : currentValue.count
          ),
        0
      );
    },
    selectSupplier(item) {
      if (!this.product) {
        return;
      }
      const product = this.product;
      const suppliers = product.suppliers || [];
      delete product.suppliers;
      suppliers.push(product);
      item.suppliers = suppliers.filter(supplier => supplier !== item);
      this.SET_XLS_SUPPLIER_MODAL_PRODUCT(null);
      this.UPDATE_XLS_ITEM_BY_PRODUCT_ID({ productId: this.product.productId, item });
    },
    arrayMin: arr => normalizePriceMarkup(arr.reduce((acc, p) => Math.min(acc, getPrice(p)), Infinity)),
    normalizePriceMarkup,
    getPrice,
  }
};
</script>

<style scoped>

</style>
