<template>
  <div>
    <div class="m-md">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <span class="breadcrumb-item" @click="$router.push('/tecdoc/brand')">Общий каталог (TecDoc)</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span class="breadcrumb-item" @click="$router.push('/tecdoc/brand')">Бренды</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span
            class="breadcrumb-item"
            @click="manufacturerId !== null ? $router.push(`/tecdoc/model/${manufacturerId}`) : $router.push('/tecdoc/brand')"
          >
            Модели
          </span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span
            class="breadcrumb-item"
            @click="$router.push(
              `/tecdoc${manufacturerId !== null && modelId !== null
                ? `/modifications/${manufacturerId}/${modelId}`
                : '/tecdoc/brand'}`
            )"
          >
            Модификации
          </span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span class="breadcrumb-item">Категории</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <TecDocOffline />

    <div class="categories-layout">
      <div class="p-10 catalog-tree">
        <el-tree
          :data="categories"
          :props="defaultProps"
          @node-click="handleNodeClick"
          empty-text=""
        />
      </div>

      <div class="category-products">
        <div v-if="articles.length">
          <n-table
            :data="articles"
            :columns="[
              { name: 'Деталь', prop: 'description', width: 'minmax(110px, 2fr)' },
              { name: 'Бренд', prop: 'brandName', width: 'minmax(40px, 1fr)' },
              { name: 'Артикул', prop: 'number', width: '120px', show: windowSize.width > 700 },
              {
                name: 'Подробно',
                prop: 'info',
                width: '75px',
                show: windowSize.width > 700,
                align: 'center'
              },
              {
                name: 'Подобрать',
                prop: 'find',
                width: 'minmax(50px, 80px)',
                align: 'center',
              },
            ]"
            class="table"
          >
            <template v-slot:description="{ row }">
              {{ row.description }}
              <el-button
                v-if="windowSize.width <= 700"
                @click.stop="showInfoProduct(row)"
                icon="el-icon-search"
                size="mini"
                circle
              />
            </template>
            <template v-slot:info="{ row }">
              <el-button
                @click.stop="showInfoProduct(row)"
                icon="el-icon-search"
                size="mini"
                circle
              />
            </template>
            <template v-slot:find="{ row }">
              <el-button
                @click="searchDetail(row)"
                icon="el-icon-truck"
                plain
                size="mini"
                type="primary"
              />
            </template>
          </n-table>
        </div>

        <el-dialog :title="'Деталь'" :visible.sync="dialogVisible" class="model-dialog">
          <div class="product-info">
            <el-image
              class="dialog__img"
              :src="dialogImg"
              fit="contain"
              :preview-src-list="[dialogImg]"
              v-if="dialogImg"
            />
            <div
              class="dialog__container"
            >
              {{ dialogDescription }}
              {{ dialogBrand }}
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <!--    <div v-else style="display: flex;justify-content: center;align-items: center;height: 500px;font-size: 14px">-->
    <!--        Ничего не найдено-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TecDocOffline from '@/components/tecdoc/offline';

export default {
  name: 'Categories',
  components: { TecDocOffline },
  metaInfo: {
    title: 'Категории TecDoc'
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      localCategoryId: null,
      // modificationId: this.$route.params.modificationId,
      dialogVisible: false,
      dialogImg: null,
      dialogBrand: null,
      dialogDescription: null,
    };
  },
  computed: {
    ...mapGetters({
      categories: 'tecdoc/categories',
    }),
    ...mapState('ui', ['windowSize']),
    modificationId() {
      return this.$route.params.modificationId;
    },
    articles() {
      return this.$store.state.tecdoc.articles;
    },
    modelId() {
      return this.$store.state.tecdoc.modelId;
    },
    manufacturerId() {
      return this.$store.state.tecdoc.manufacturerId;
    },
    height() {
      return 'calc(100vh - 78px)';
    },
  },
  async mounted() {
    await this.$store.dispatch('tecdoc/GetTecdocCategories', {
      modificationId: this.modificationId
    });
  },
  methods: {
    async handleNodeClick({ id }) {
      this.localCategoryId = id;
      await this.$store.dispatch('tecdoc/GetArticlesSimplified', {
        categoryId: id,
        modificationId: this.modificationId
      });
    },
    showInfoProduct(row) {
      this.dialogVisible = true;
      this.dialogBrand = row.brandName;
      this.dialogDescription = row.description;
      this.dialogImg = row.imageUrl;
    },

    async searchDetail({ brandName, number }) {
      await this.$store.dispatch('products/GetSupplierProducts', {
        brand: brandName,
        article: number,
        tableType: 'part',
      })
        .then(() => {
          this.$router.push(`/part/${brandName}/${number}`);
        })
        .catch(() => this.$alert('Подходящих предложений не найдено', 'Подбор', {
          confirmButtonText: 'OK',
        }));
    },
  }
};
</script>

<style lang="sass">
.model-dialog .el-dialog
  width: 100%
  max-width: 400px
  @media (max-width: 400px)
    width: calc(100% - 40px)
</style>
<style scoped lang="sass">
.categories-layout
  display: flex
  @media (max-width: 700px)
    flex-direction: column
.catalog-tree
  width: 270px
  min-width: 270px !important
  height: calc(100vh - 100px)
  overflow-y: auto
  overflow-x: hidden
  @media (max-width: 700px)
    max-height: 40vh
    overflow-y: auto
    width: 100%
.category-products
  width: calc(100% - 270px)
  padding: 10px
  @media (max-width: 700px)
    width: auto
</style>
