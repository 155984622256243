import 'element-ui/lib/theme-chalk/index.css';
import './assets/sass/commons.sass';
import 'shepherd.js/dist/css/shepherd.css';

import * as Sentry from '@sentry/vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import Vue from 'vue';
import VueMeta from 'vue-meta';

import {
  NBlockTable,
  NInputNumber,
  NTable,
} from '@/ui-kit';

import App from './App.vue';
import vueBemCn from './bem/vue-plugin';
import priceOutput from './components/price/priceOutput';
import router from './router';
import store from './store';

Vue.use(VueMeta);
Vue.use(ElementUI, { locale });
Vue.use(ElementUI.Loading.directive);

Vue.use(vueBemCn, {
  hyphenate: true,
  delimiters: {
    ns: '', // namespace
    el: '__', // element delimeter
    mod: '_', // modifier delimeter
    modVal: '-', // value delimeter for modifier
  },
});

Vue.component('PriceOutput', priceOutput);
Vue.component('NTable', NTable);
Vue.component('NBlockTable', NBlockTable);
Vue.component('NInputNumber', NInputNumber);

if (window.location.href.indexOf('localhost') === -1) {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VUE_APP_SENTRY_URL,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      })
    ],
    tracesSampleRate: import.meta.env.PROD ? 0.5 : 1.0,
    environment: import.meta.env.PROD ? 'production' : 'development',
  });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
