<template>
  <div>
    <div
      class="empty-text"
      v-if="showButton"
    >
      <span class="empty-text__title">Товар не найден</span>
      <span>Попробуйте изменить запрос или искать во всём каталоге</span>
      <el-button class="empty-text__button" size="small" @click="searchAll">
        Искать во всём каталоге
      </el-button>
    </div>
    <template v-else>
      {{ emptyText }}
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'EmptyTable',
  props: {
    emptyText: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('products', ['products', 'search']),
    ...mapState('categories', ['currentCategory']),
    showButton() {
      const { emptyText, currentCategory } = this;
      return emptyText === 'Поиск не дал результатов, попробуйте изменить запрос' && currentCategory !== 0;
    }
  },
  methods: {
    ...mapActions('products', ['GetProductsList']),
    ...mapMutations('products', ['SET_CATEGORY_ID', 'SET_SEARCH_TEXT', 'SET_EMPTY_TEXT']),
    ...mapMutations('categories', ['SET_CURRENT_CATEGORY']),

    async searchAll() {
      this.SET_EMPTY_TEXT('Загрузка');
      this.SET_CATEGORY_ID(0);
      this.SET_CURRENT_CATEGORY(0);

      const { search } = this;

      await this.GetProductsList({
        categoryId: 0,
        search,
      })
        .then(() => {
          if (this.products.length === 0) {
            this.SET_EMPTY_TEXT('Поиск не дал результатов, попробуйте изменить запрос');
          }
        })
        .catch((error) => {
          this.$alert(error, 'Произошла ошибка', {
            type: 'error'
          });
        });
    }
  }
};
</script>

<style scoped lang="sass">
.empty-text
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center
  &__title
    font-weight: bold
    color: #6d7075
    margin-bottom: 5px
  &__button
    margin-top: 10px

</style>
