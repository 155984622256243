import { formatDateNumeric } from 'utils/formatDate';
import store from '../store';

export const weekDays = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
];
export const dayOfWeek = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};
const dayOfWeekTranslate = {
  monday: 'понедельника',
  tuesday: 'вторника',
  wednesday: 'среды',
  thursday: 'четверга',
  friday: 'пятницы',
  saturday: 'субботы',
  sunday: 'воскресенья',
};

export const regionalWarehouseDeliveryDays = 3;

/**
 *
 * @param {Date} date
 * @return {number|null} Is date available for delivery and how many days needed to ship
 */
export function getDeliveryDaysForDate(date) {
  if (!store.state.accounts.deliveryDates) {
    return null;
  }
  const weekDayNumber = date.getDay();
  const deliveryToHour = store.state.accounts.deliveryDates[`${weekDays[weekDayNumber]}Time`];
  const hours = new Date().getHours() + 3;

  // Если дата сегодняшняя и времени уже больше чем граничащее время доставки, то эта дата недоступна
  if (date.toDateString() === new Date().toDateString() && deliveryToHour && hours > deliveryToHour) {
    return null;
  }

  return store.state.accounts.deliveryDates[weekDays[weekDayNumber]] ?? null;
}

/**
 *
 * @param {Date} date
 * @param {number} days
 * @return {Date}
 */
export function addDays(date, days) {
  return new Date(new Date(date).setDate(date.getDate() + days));
}

/**
 *
 * @param {Date} firstDate
 * @param {Date} secondDate
 * @return {Boolean}
 */
export function hasSundayInDateRange(firstDate, secondDate) {
  if (secondDate < firstDate) {
    return false;
  }
  while (firstDate <= secondDate) {
    if (firstDate.getDay() === 0) {
      return true;
    }
    firstDate = addDays(firstDate, 1);
  }
  return false;
}

/**
 * Get closest delivery date
 * @param {boolean} isRegionalWarehouse
 * @param {number} deliveryDays
 * @param startDate
 * @return {null|{stockDate: Date, deliveryDate: Date}}
 */
export function getDeliveryDate(
  {
    isRegionalWarehouse = false,
    deliveryDays = 0,
    startDate = new Date()
  } = {}
) {
  if (!store.state.accounts.deliveryDates) {
    return null;
  }
  for (let dayCount = 0; dayCount < 42; dayCount++) {
    let stockDate = addDays(startDate, dayCount);

    if (isRegionalWarehouse) {
      let regionalDeliveryDate = addDays(stockDate, regionalWarehouseDeliveryDays);
      if (hasSundayInDateRange(stockDate, regionalDeliveryDate)) {
        regionalDeliveryDate = addDays(stockDate, 1);
      }

      stockDate = regionalDeliveryDate;
    }
    if (deliveryDays) {
      stockDate = addDays(stockDate, deliveryDays);
    }
    const deliveryDaysForDate = getDeliveryDaysForDate(stockDate);
    const deliveryDate = addDays(stockDate, deliveryDaysForDate);

    if (deliveryDaysForDate !== null) {
      return {
        stockDate,
        deliveryDate
      };
    }
  }
  return null;
}

/**
 * @return {string|null}
 */
export function findNextDate({ isRegionalWarehouse = false, deliveryDays = 0 } = {}) {
  if (!store.state.accounts.deliveryDates) {
    return null;
  }
  const startDate = getDeliveryDate(
    {
      isRegionalWarehouse,
      deliveryDays
    }
  ).deliveryDate;
  if (!startDate) {
    return null;
  }
  for (let dayCount = 1; dayCount <= 20; dayCount++) {
    const deliveryDate = getDeliveryDate(
      {
        isRegionalWarehouse,
        deliveryDays: deliveryDays + dayCount,
        startDate
      }
    );
    if (deliveryDate !== null) {
      return `Иначе следующая дата доставки будет ${formatDateNumeric(deliveryDate.deliveryDate)}`;
    }
  }
  return '';
}

function getPreviousDay(day) {
  const result = day - 1;
  if (result === 0) {
    return 7;
  }
  return result;
}


export function getDeliveryHint(
  {
    isRegionalWarehouse = false,
    deliveryDays = 0
  } = {}
) {
  if (!store.state.accounts.deliveryDates) {
    return null;
  }
  let deliveryDay = new Date();
  if (isRegionalWarehouse) {
    let regionalDeliveryDate = addDays(deliveryDay, regionalWarehouseDeliveryDays);

    if (hasSundayInDateRange(deliveryDay, regionalDeliveryDate)) {
      regionalDeliveryDate = addDays(deliveryDay, 1);
    }

    deliveryDay = regionalDeliveryDate;
  }
  if (deliveryDays) {
    deliveryDay = addDays(deliveryDay, deliveryDays);
  }
  let dayCount = 0;
  let day = deliveryDay.getDay();
  const hours = deliveryDay.getHours() + 6;
  let dayName = dayOfWeek[day];
  while (dayCount <= 7) {
    if (store.state.accounts.deliveryDates[dayName] !== null) {
      if (dayCount === 0) {
        const time = store.state.accounts.deliveryDates[`${dayName}Time`];
        if (time && hours < time) {
          return `Успейте сделать заказ до ${time}:00 по Омскому времени, чтобы товары доставили
          ${formatDateNumeric(addDays(deliveryDay, store.state.accounts.deliveryDates[dayName]))}.
          ${findNextDate({ isRegionalWarehouse, deliveryDays })}`;
        }
        const nextDay = dayOfWeek[day + 1];
        if (store.state.accounts.deliveryDates[nextDay] !== null) {
          return `Успейте сделать заказ до 16:00 по Омскому времени, чтобы товары доставили
          ${formatDateNumeric(addDays(deliveryDay, store.state.accounts.deliveryDates[dayName]))}.
          ${findNextDate({ isRegionalWarehouse, deliveryDays })}`;
        }
      } else {
        if (dayCount === 1 && !store.state.accounts.deliveryDates[`${dayName}Time`]) {
          return `Успейте сделать заказ до 16:00 по Омскому времени, чтобы товары доставили
          ${formatDateNumeric(addDays(deliveryDay, store.state.accounts.deliveryDates[dayName] + dayCount))}.
          ${findNextDate({ isRegionalWarehouse, deliveryDays })}`;
        }
        return `Оформите заказ до ${dayOfWeekTranslate[dayOfWeek[getPreviousDay(day)]]} включительно для доставки
        ${formatDateNumeric(addDays(deliveryDay, store.state.accounts.deliveryDates[dayName] + dayCount))}`;
      }
    }
    dayCount += 1;
    day += 1;
    if (day > 7) {
      day = 1;
    }
    dayName = dayOfWeek[day];
  }
  return 'Не удалось определить дату доставки';
}
