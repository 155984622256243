<template>
  <div>
    <h2>Авторизация</h2>
    <el-form
      hide-required-asterisk
      :model="model"
      status-icon
      :rules="rules"
      ref="loginForm"
      label-width="120px"
      label-position="top"
      class="demo-ruleForm"
      @submit.prevent.native="submitForm('loginForm')"
    >
      <el-form-item label="Email" prop="email" :error="error">
        <el-input v-model="model.email" autocomplete="email" @keypressEnter="keypress" type="email" class="form-field" />
      </el-form-item>
      <el-form-item label="Пароль" prop="pass" :error="errorPass">
        <el-input
          class="form-field"
          v-model="model.pass"
          id="pass"
          minlength="6"
          show-word-limit
          type="password"
          autocomplete="off"
          show-password
          @keypressEnter="keypressSubmit"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit" :loading="sending">
          Войти
        </el-button>
        <div class="forgot-link-position">
          <ForgotPass :start-email="model.email" />
        </div>
      </el-form-item>
    </el-form>
    <hr>
    <h3>Протестируйте наш сервис</h3>
    <h4>
      Для этого вы можете войти как гость. В режиме гостя <u>вы не сможете сделать заказ</u>,
      так же мы не гарантируем сохранность товаров в корзине
    </h4>
    <el-button size="small" @click="authRequestGuest">
      Войти как гость
    </el-button>
  </div>
</template>

<script>
import ForgotPass from '../../components/ForgotPass';

export default {
  name: 'AppLogin',
  metaInfo: {
    title: 'Войти в онлайн каталог ТФ «Николь»',
    titleTemplate: null
  },
  components: { ForgotPass },
  data() {
    return {
      error: '',
      errorPass: '',
      model: {
        pass: '',
        email: '',
      },
      rules: {
        pass: [
          {
            required: true, message: 'Пожалуйста введите пароль', trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: 'Пожалуйста введите ваш email', trigger: 'blur' },
          { type: 'email', message: 'Проверьте корректность введенного email', trigger: 'blur' }
        ]
      },
      sending: false,
    };
  },
  methods: {
    keypress() {
      document.querySelector('#pass').focus();
    },
    keypressSubmit() {
      this.submitForm('loginForm');
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.authRequest();
          return true;
        }
        return false;
      });
    },
    async authRequest() {
      this.sending = true;
      this.error = '';
      this.errorPass = '';
      await this.$store.dispatch(
        'auth/AuthRequest',
        {
          email: this.model.email,
          password: this.model.pass,
        },
      ).then(() => {
        this.$router.push('/products');
        this.$store.commit('accounts/SET_EMAIL', this.model.email);
      }).catch((error) => {
        this.error = error.messageLogin;
        this.errorPass = error.messagePass;
        if (error.message) {
          this.error = error.message;
        }
      }).finally(() => {
        this.sending = false;
      });
    },
    async authRequestGuest() {
      await this.$store.dispatch(
        'auth/AuthRequest',
        {
          email: this.model.email,
          password: this.model.pass,
          guest: true
        },
      ).then(() => {
        this.$router.push('/products');
        this.$store.commit('accounts/SET_EMAIL', 'testuser@email.com');
      }).catch(() => {
        this.$message.error('Произошла ошибка, попробуйте позже');
      });
    }
  }
};
</script>

<style src="./auth.sass" scoped lang="sass" />
