<template>
  <div class="filters">
    <div v-for="(filter, i) in filters" :key="`filters---${i}`">
      <component :is="components[filter.type]" :filter="filter" />
    </div>

    <div class="filters__buttons" v-if="filters.length">
      <el-button
        type="primary"
        class="set-filters"
        @click="filterOut"
      >
        Применить
      </el-button>
      <el-button
        class="set-filters"
        size="small"
        @click="resetFilters"
      >
        Сбросить
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import filterCheckbox from './filters/filterCheckbox.vue';
import filterRange from './filters/filterRange.vue';

const components = {
  checkbox: 'filterCheckbox',
  range: 'filterRange'
};

export default {
  name: 'PartsFilters',
  components: { filterCheckbox, filterRange },
  data() {
    return {
      components,
      selectedFilters: [],
    };
  },
  computed: {
    ...mapState(
      'products',
      ['supplierProducts', 'suppliersLoading', 'changedFilters']
    ),
    ...mapState('ui', ['userCurrency']),
    queryToApply() {
      return this.changedFilters.reduce((preview, item) => ({
        ...preview,
        [item.name]: item.value ? item.value : `${item.gt}-${item.lt}`
      }), {});
    },
    filters() {
      if (this.suppliersLoading) {
        return [
          {
            id: 2,
            name: 'brand',
            categoryId: null,
            values: [],
            filterTypeId: 2,
            title: 'Бренд',
            type: 'checkbox'
          },
          {
            id: 1,
            name: 'price',
            categoryId: null,
            values: {
              rub: [
                0,
                0
              ],
              tenge: [
                0,
                0
              ],
              tengeCourse: '1'
            },
            filterTypeId: 1,
            title: 'Цена',
            type: 'range'
          }
        ];
      }
      if (!this.supplierProducts.products.length && !this.supplierProducts.analog.length) {
        return [];
      }
      const products = (this.supplierProducts.products || []);
      const productsBrands = products.map(product => product.brand);

      const analogs = (this.supplierProducts.analog || []);
      const analogsBrands = analogs.map(analog => analog.brand);

      const productsPrices = (this.supplierProducts.products || [])
        .map(product => product.stocks.map(stock => stock[this.userCurrency !== 'RU' ? 'tengePrice' : 'price']))
        .flat();
      const analogsPrices = (this.supplierProducts.analog || [])
        .map(analog => analog.stocks.map(stock => stock[this.userCurrency !== 'RU' ? 'tengePrice' : 'price']))
        .flat();
      const allPrices = [...productsPrices, ...analogsPrices];

      const filters = [
        {
          id: 2,
          name: 'brand',
          categoryId: null,
          values: [
            ...productsBrands,
            ...analogsBrands,
          ].filter((item, index) => [...productsBrands, ...analogsBrands].indexOf(item) === index),
          filterTypeId: 2,
          title: 'Бренд',
          type: 'checkbox'
        },
      ];
      if (allPrices.length) {
        filters.push({
          id: 1,
          name: 'price',
          categoryId: null,
          values: {
            rub: [
              Math.min(...allPrices),
              Math.max(...allPrices)
            ],
            tenge: [
              Math.min(...allPrices),
              Math.max(...allPrices)
            ],
            tengeCourse: '1'
          },
          filterTypeId: 1,
          title: 'Цена',
          type: 'range'
        });
      }
      return filters;
    }
  },
  methods: {
    ...mapMutations(
      'products',
      ['REMOVE_CHANGED_FILTERS']
    ),
    filterOut() {
      if (JSON.stringify(this.$route.query) === JSON.stringify(this.queryToApply)) {
        return;
      }
      this.$router.replace({
        path: this.$route.path,
        query: this.queryToApply
      });
    },
    async resetFilters() {
      if (JSON.stringify(this.$route.query) === '') {
        return;
      }
      this.$root.$emit('resetFilters');
      this.REMOVE_CHANGED_FILTERS();
      await this.$router.replace({
        path: this.$route.path,
        query: undefined
      });
    }
  }
};
</script>

<style scoped lang="sass">
  .filters
    &__buttons
      width: 100%
      margin-top: 30px
      display: grid
      grid-gap: 10px
  .filter
    margin-top: 16px
    padding-top: 7px
    border-top: 1px solid #ebeef5
    &__title
      display: block
      font-size: 11px
      font-weight: bold
</style>
