<template>
  <div class="xls-errors">
    <div class="container">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-lg">
        <el-breadcrumb-item :to="{ path: '/upload-xls' }">
          Загрузка Excel
        </el-breadcrumb-item>
        <el-breadcrumb-item>Черновик</el-breadcrumb-item>
      </el-breadcrumb>
      <page-header>
        Черновик
        <template v-slot:subtitle>
          Здесь собраны товары, в которых были обнаружены ошибки при загрузке excel-файла
        </template>
      </page-header>

      <div v-loading="true" v-if="xlsErrorsLoading" class="loader" />
      <n-table
        :data="xlsErrors"
        :columns="[
          { name: 'Название', prop: 'name', width: '300px' },
          { name: 'Артикул', prop: 'article', width: '150px', },
          { name: 'Бренд', prop: 'brand', width: '130px', },
          { name: 'Кол-во', prop: 'quantity', width: '93px', align: 'center' },
          { name: 'Статус', prop: 'status', width: '1fr', align: 'center' },
          { name: '', prop: 'orderName', width: '1fr' },
          { name: '', prop: 'action', width: '62px', align: 'center' },
          { name: 'Удалить', prop: 'del', width: '62px', align: 'center' },
        ]"
      >
        <template v-slot:quantity="{ row }">
          <div v-if="!row.stocks" style="text-align: center">
            <n-input-number
              v-model="row.quantity"
              :min="+row.minShippingRate"
              :max="calcMax(row)"
              :step="+row.minShippingRate"
              :step-strictly="true"
              @change="(e) => manualFixMultiplicity(row, e)"
            />
          </div>
          <template v-else-if="row.stocks">
            <span
              :title="'до ' + getRowStocksCount(row.stocks) + ' шт.'"
              class="hide-overflow"
            >до {{ getRowStocksCount(row.stocks) }} шт.
            </span>
          </template>
        </template>
        <template v-slot:status="{ row }">
          <div>
            {{ xlsErrorDescriptions[row.status] }}
            <div v-if="row.status === 'notEnoughQuantity' && row.stockBalance > 0">
              (в наличии {{ row.stockBalance }})
            </div>
          </div>
        </template>
        <template v-slot:action="{ row }">
          <el-tooltip effect="dark" content="В ожидание" v-if="row.status === 'notEnoughQuantity'">
            <el-button
              size="mini"
              @click.prevent.stop="addWishList(row)"
              icon="el-icon-alarm-clock"
            />
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :content="'Изменить количество на ' + row.stockBalance"
            v-if="row.status === 'notEnoughQuantity' && row.stockBalance > 0"
          >
            <el-button
              size="mini"
              @click="fixXlsError(row)"
              icon="el-icon-edit"
            />
          </el-tooltip>
          <el-tooltip
            effect="dark"
            content="Исправить"
            v-if="row.status === 'incorrectMultiplicity' || row.status === 'severalSuppliers'"
          >
            <el-button
              size="mini"
              @click="fixXlsError(row)"
              icon="el-icon-edit"
            />
          </el-tooltip>
          <el-tooltip
            effect="dark"
            content="Добавить в корзину"
            v-if="row.status === 'ready'"
          >
            <el-button
              size="mini"
              @click="addToCart(row)"
              icon="el-icon-shopping-cart-2"
            />
          </el-tooltip>
        </template>
        <template v-slot:del="{ row }">
          <el-button
            icon="el-icon-delete"
            plain
            size="mini"
            type="danger"
            @click="removeProduct(row)"
          />
        </template>
      </n-table>
      <el-empty
        v-if="!xlsErrorsLoading && !xlsErrors.length" description="У вас нет черновиков"
      />
      <div class="xls-errors-footer">
        <el-button
          class="footer__button"
          @click="removeAllProducts()"
          v-if="xlsErrors.length"
        >
          Удалить всё
        </el-button>
        <el-select
          v-model="selectedCheckout"
          clearable
          placeholder="Корзины"
          class="footer__button"
          v-if="xlsErrors.length"
          @change="selectCheckout"
        >
          <el-option
            v-for="item in baskets"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { xlsErrorDescriptions } from '@/views/xls/components/xlsOrderList.vue';
import PageHeader from '@/components/page-header/page-header';
import { mapActions, mapMutations, mapState } from 'vuex';
import Vue from 'vue';

export default {
  name: 'XlsErrors',
  components: {
    PageHeader,
  },
  metaInfo: {
    title: 'Черновики'
  },
  data() {
    return {
      xlsErrorDescriptions,
      selectedCheckout: '',
    };
  },
  computed: {
    ...mapState('products', ['xlsErrors', 'xlsErrorsLoading']),
    ...mapState('basket', ['multiBasket']),
    baskets() {
      return [
        {
          in: 0,
          name: 'Создать новую корзину'
        },
        ...this.multiBasket
      ];
    }
  },
  async mounted() {
    await this.getXlsErrors();
  },
  methods: {
    ...mapActions('products', ['getXlsErrors', 'removeProductFromXlsErrors', 'removeAllProductsFromXlsErrors', 'fixXlsError']),
    ...mapActions('wishlist', ['addToWishList']),
    ...mapActions('basket', ['XlsUploadBasket', 'GetProductsBasket']),
    ...mapMutations('basket', ['SET_ACTIVE_BASKET']),
    async addWishList(row) {
      this.addToWishList({
        productId: row.productId,
        quantity: row.quantity,
        basketName: this.activeBasketData?.name || '',
      }).then(() => { this.$message.success('Товар добавлен в лист ожидания'); })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось добавить товар в лист ожидания'); });
    },
    async removeProduct(row) {
      await this.removeProductFromXlsErrors(row.id);
      this.$message.success('Товар удалён из черновика');
    },
    async removeAllProducts() {
      await this.removeAllProductsFromXlsErrors();
      this.$message.success('Товары удалёны из черновика');
    },
    getRowStocksCount(stocks) {
      return stocks.reduce(
        (value, currentValue) => value
          + (typeof currentValue.count === 'string'
            ? parseInt(currentValue.count.replace(/\D/g, ''), 10)
            : currentValue.count
          ),
        0
      );
    },
    manualFixMultiplicity(item) {
      const productIndex = this.xlsErrors.findIndex(product => product.productId === item.productId);
      if (item.status === 'incorrectMultiplicity' && item.quantity % Number(item.minShippingRate) === 0) {
        item.status = 'ready';
        Vue.set(this.xlsErrors, productIndex, item);
      }
      if (item.status === 'ready' && item.quantity % Number(item.minShippingRate) !== 0) {
        item.status = 'incorrectMultiplicity';
        Vue.set(this.xlsErrors, productIndex, item);
      }
    },
    calcMax(row) {
      return row.stockBalance - (row.stockBalance % row.minShippingRate);
    },
    addToCart(product) {
      this.XlsUploadBasket({
        products: [product],
        basketId: undefined
      })
        .then(async () => {
          this.$message({
            type: 'success',
            message: 'Товар добавлен в корзину',
          });
          await this.removeProductFromXlsErrors(product.id);
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err,
          });
        });
    },
    selectCheckout(el) {
      this.selectedCheckout = el;
      if (el) {
        this.SET_ACTIVE_BASKET(el);
        this.GetProductsBasket();
      }
    },
  }
};
</script>

<style scoped lang="sass">
.xls-errors
  padding: 20px
  margin: 0 auto
  overflow: auto
  .container
    max-width: 1000px
    margin: 0 auto
  .xls-errors-footer
    padding: 20px 0 0
    display: flex
    flex-flow: nowrap
    justify-content: flex-end
    margin-top: auto
    gap: 20px
</style>
