<template>
  <el-dialog :title="infoProduct.name" :width="windowSize.width > 830 ? '800px' : 'calc(100% - 30px)'" :visible.sync="infoProduct.visible">
    <div class="product-info">
      <el-image
        :src="`${urlSrc + infoProduct.externalId}.JPEG`"
        :preview-src-list="[`${urlSrc + infoProduct.externalId}.JPEG`]"
        class="dialog__img"
        fit="contain"
        lazy
      >
        <template v-slot:error>
          <span class="image-slot">нет изображения</span>
        </template>
      </el-image>
      <div class="dialog__container">
        <ul class="product-info__list">
          <li>
            <strong>Бренд:</strong> <span>{{ infoProduct.brand || '-' }}</span>
          </li>
          <li>
            <strong>Код 1С:</strong> <span>{{ infoProduct.externalId || '-' }}</span>
          </li>
          <li>
            <strong>ОЕМ:</strong> <span>{{ infoProduct.OEM || '-' }}</span>
          </li>
          <li>
            <strong>Артикул:</strong> <span>{{ infoProduct.article || '-' }}</span>
          </li>
        </ul>

        <p v-if="infoProduct.description" v-text="infoProduct.description" class="dialog__description" ref="description" />
        <p v-else class="dialog__description">
          Информацию о товаре уточняйте у менеджеров компании
        </p>
        <a v-if="infoProduct.link" :href="infoProduct.link" target="_blank" class="link">
          Посмотреть на сайте производителя <i class="el-icon-truck" />
        </a>
        <p class="dialog__price" v-if="infoProduct">
          <basket-indicator v-if="infoProduct.baskets" :baskets="infoProduct.baskets" class="dialog__price__baskets" />
          <span class="dialog__price__price">
            <priceOutput :price="price" />
          </span>
          <add-to-cart
            v-if="infoProduct.stockbalance + infoProduct.regionalstockbalance !== 0 || returnBasketId !== null"
            v-model="infoProduct.quantity"
            :loading="infoProduct.id === loadingIndex"
            :disabled="infoProduct.stockbalance + infoProduct.regionalstockbalance === 0 && returnBasketId === null"
            :min="+infoProduct.minShippingRate || 1"
            :max="maxCalc(infoProduct)"
            :step="+infoProduct.minShippingRate"
            @addToCart="addProduct(infoProduct)"
          />
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { normalizePriceMarkup, getPrice } from '@/utils/normalizePrice';
import { mapActions, mapMutations, mapState } from 'vuex';
import BasketIndicator from '@/components/product-table/layout/basketIndicator';
import AddToCart from '@/components/add-to-cart/addToCart';

/**
 * @typedef infoProduct
 * @property {string} article
 * @property {Object | string} baskets
 * @property {string} brand
 * @property {number} categoryId
 * @property {string} description
 * @property {string} externalId
 * @property {number} id
 * @property {number} isProductGroup
 * @property {string} link
 * @property {number} mainStockBalance
 * @property {string} minShippingRate
 * @property {string} name
 * @property {string} price
 * @property {string} productsinbaskets
 * @property {number} quantity
 * @property {number} stockbalance
 * @property {number} regionalstockbalance
 * @property {string} tengePrice
 * @property {boolean} visible
 */

export default {
  name: 'InfoProduct',
  components: {
    AddToCart,
    BasketIndicator
  },
  props: {
    /** @type infoProduct */
    infoProduct: {
      type: Object,
      required: true,
    },
    loadingIndex: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      urlSrc: import.meta.env.VUE_APP_IMG_URL,
      loadingButton: false
    };
  },
  computed: {
    ...mapState('ui', ['userCurrency', 'windowSize']),
    ...mapState('returns', {
      returnBasketId: state => state.basketId,
    }),
    price() {
      if (!this.infoProduct.price) return '';
      return normalizePriceMarkup(getPrice(this.infoProduct)) || '';
    }
  },
  watch: {
    infoProduct() {
      this.$nextTick(() => {
        if (this.$refs.description) {
          this.$refs.description.scrollTo(0, 0);
        }
      });
    },
  },
  methods: {
    ...mapMutations('products', ['SET_ANALOGS_FOR_ID', 'SET_PRODUCT_CART_LOADING_INDEX']),
    ...mapActions('basket', ['AddProductInBasket']),
    async addProduct(product) {
      this.SET_PRODUCT_CART_LOADING_INDEX(product.id);
      await this.AddProductInBasket(product)
        .catch(({ message }) => {
          this.handleAddProductErrors(message);
        });
      product.quantity = +product.minShippingRate;
      this.SET_PRODUCT_CART_LOADING_INDEX(null);
    },
    handleAddProductErrors(errorMessage) {
      this.$alert(errorMessage, 'Добавление в корзину', {
        confirmButtonText: 'OK',
      });
    },
    maxCalc(row) {
      return row.stockbalance + row.regionalstockbalance - ((row.stockbalance + row.regionalstockbalance) % +row.minShippingRate);
    }
  }
};
</script>

<style scoped lang="sass">
.product-info
  display: flex
  align-items: flex-start
  max-height: 70vh
  width: 90%
  max-width: 600px
  @media (max-width: 800px)
    flex-flow: column
  &__list
    list-style-type: none
    margin: 0 0 1em 0
    padding: 0

.dialog
  &__description
    margin: 1em 0
    white-space: pre-wrap
    overflow-y: auto
    max-height: 250px
  &__img
    position: sticky
    top: 20px
    width: 45%
    max-height: 100%
    padding-right: 10px
    flex-shrink: 0
    box-sizing: border-box
    @media (max-width: 800px)
      width: 100%
      margin-bottom: 10px
      padding-right: 0
      position: initial
  &__container
    display: flex
    flex-direction: column
    padding: 0 10px
    flex-grow: 1
  &__price
    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: auto
    padding-top: 10px
    &__price
      font-size: 16px
      font-weight: bold
    > *:not(:first-child)
      margin-left: 10px
    &__baskets
      margin: 0 auto 0 0

</style>
