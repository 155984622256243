<template>
  <div>
    <el-button type="text" @click="dialogFormVisible = true">
      {{ buttonText }}
    </el-button>

    <el-dialog title="Восстановить пароль" :visible.sync="dialogFormVisible" :width="windowSize.width < 800 ? '90%' : '30%'">
      <el-form class="forgot" hide-required-asterisk :model="model" status-icon :rules="rules" ref="forgotForm" v-if="!showSuccess">
        <el-form-item label="Email" prop="email" :error="error">
          <el-input v-model="model.email" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('forgotForm')">
            Восстановить
          </el-button>
        </el-form-item>
      </el-form>
      <div v-else>
        <div>
          <div class="success-forgot">
            <i class="el-icon-message" />
          </div>

          <div class="success-forgot__text">
            Письмо с дальнейшими инструкциями отправлено на вашу почту
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ForgotPass',
  props: {
    buttonText: {
      type: String,
      default: 'Забыли пароль?'
    },
    startEmail: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      error: '',
      dialogFormVisible: false,
      model: {
        email: this.startEmail,
      },
      rules: {
        email: [
          { required: true, message: 'Пожалуйста введите ваш email', trigger: 'blur' },
          { type: 'email', message: 'Проверьте корректность введенного email', trigger: 'blur' }
        ]
      },
      showSuccess: false
    };
  },
  computed: {
    ...mapState('ui', ['windowSize']),
  },
  watch: {
    startEmail() {
      this.model.email = this.startEmail;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.forgotRequest();
          return true;
        }
        return false;
      });
    },
    async forgotRequest() {
      await this.$store.dispatch(
        'auth/ForgotPass',
        {
          email: this.model.email,
        },
      ).then(() => {
        this.showSuccess = true;
      }).catch((error) => {
        this.error = error.message;
      });
    },
  },
};
</script>

<style scoped>
.forgot {
  padding: 0 15px 10px;
  margin-top: -20px;
}

  .el-icon-message{
    font-size: 55px;
    color: #3D8F5F;
  }

  .success-forgot{
    display: flex;
    justify-content: center;
  }

  .forgot button {
    margin-top: 20px;
  }

  .success-forgot__text {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
</style>
