<template>
  <el-form
    hide-required-asterisk
    :model="model"
    status-icon
    :rules="rules"
    ref="regForm"
    label-width="120px"
    label-position="top"
    class="demo-ruleForm"
  >
    <h2>Восстановление пароля</h2>
    <span class="login-card__user-email"><span>Ваш email: </span>{{ model.email }}</span>

    <el-form-item label="Пароль" prop="pass">
      <el-input
        v-model="model.pass"
        minlength="6"
        show-word-limit
        type="password"
        autocomplete="off"
        placeholder="Придумайте новый пароль для личного кабинета"
        show-password
      />
    </el-form-item>

    <ForgotPass class="forgot-pass" button-text="Отправить код повторно" :start-email="model.email" />

    <el-alert v-if="error" :title="error" type="error" @close="error = ''" />

    <el-form-item>
      <el-button :loading="isLoading" type="primary" @click="submitForm('regForm')">
        Сохранить и войти
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex';
import ForgotPass from '../../components/ForgotPass';

export default {
  name: 'AppRestorePassword',
  components: {
    ForgotPass
  },
  metaInfo: {
    title: 'Восстановление пароля'
  },
  data() {
    return {
      isLoading: false,
      error: '',
      model: {
        pass: '',
        email: '',
        code: '',
      },
      rules: {
        pass: [
          {
            required: true, message: 'Пожалуйста введите пароль', trigger: 'blur'
          }
        ],
      }
    };
  },
  async created() {
    const params = window
      .location
      .search
      .replace('?', '')
      .split('&')
      .reduce(
        (p, e) => {
          const a = e.split('=');
          p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
          return p;
        },
        {}
      );

    if (!params.email || !params.code) {
      await this.$router.replace({ name: 'app-register' });
      return;
    }

    this.model.email = params.email;
    this.model.code = params.code;
  },
  methods: {
    ...mapActions('auth', ['RegRequest', 'HasUserPassword']),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registerRequest();
          return true;
        }

        return false;
      });
    },
    async registerRequest() {
      this.isLoading = true;
      await this.RegRequest({
        email: this.model.email,
        code: this.model.code,
        password: this.model.pass,
      })
        .then(() => {
          this.error = '';
          this.$router.push('/products');
          this.$store.commit('accounts/SET_EMAIL', this.model.email);
        })
        .catch((error) => {
          if (error && error.message) {
            this.error = error.message;
          } else {
            this.error = 'Непредвиденная ошибка. Попробуйте повторить действие позднее.';
          }
        });
      this.isLoading = false;
    },
  }
};
</script>

<style src="./auth.sass" scoped lang="sass" />
