<template>
  <tr
    class="n-table-row"
    :class="{ ...classes, 'n-table-row_active': active }"
    :style="{ gridTemplateColumns: templateColumns }"
    @click="click"
  >
    <td
      v-for="({ prop, align, translucent }, colIndex) in columns"
      class="n-table-row__col"
      :class="[
        `n-table-row__col_align-${align}`,
        `${translucent ? 'n-table-row__col_translucent' : ''}`,
        `n-table-row__col_${prop}`,
        `n-table-row__row_${rowIndex}`
      ]"
      :key="`rowCol---${colIndex}`"
      @click="row.expandProperties = !row.expandProperties"
    >
      <slot :prop="prop" :row="row" :index="rowIndex">
        {{ row[prop] }}
      </slot>
    </td>
    <td v-if="propertisArray && propertisArray.length && row.expandProperties" class="n-table-row__col n-table-row__col_minor">
      <div v-for="(detail, i) in propertisArray" :key="`detail---${i}`">
        <div class="minor-info-block">
          <span v-if="!detail.hideTitleOnMobile" class="minor-info-block__title">{{ detail.name }}</span>
          <slot :prop="detail.prop" :row="row" :index="rowIndex">
            <span>{{ row[detail.prop] }}</span>
          </slot>
        </div>
      </div>
    </td>
    <slot name="after" :row="row" :columns="columns" :rowIndex="rowIndex" />
  </tr>
</template>

<script>
import { columns } from '../props';

export default {
  name: 'NTableRow',
  block: 'NTableRow',
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    columns,
    row: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true
    },
    templateColumns: {
      type: String,
      required: true,
    },
    propertisArray: {
      type: Array,
      required: true,
      default: () => []
    },
    classes: {
      type: [Object, Array, String],
      default: () => ({}),
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e);
    }
  },
};
</script>

<style lang="sass" scoped src="../table.sass"></style>
