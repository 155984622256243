import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import Admin from '@/views/admin/Admin.vue';
import AdminBackupHistory from '@/views/admin/AdminBackupHistory.vue';
import ViewAdminChangelog from '@/views/admin/AdminChangelog.vue';
import ViewAdmin from '@/views/admin/AdminLogs.vue';
import ViewAdminSynonyms from '@/views/admin/AdminSynonyms.vue';
import ViewAdminUsers from '@/views/admin/AdminUsers.vue';
import AdminLayout from '@/views/admin/layout/AdminLayout';
import AppLogin from '@/views/auth/app-login.vue';
import AppRegister from '@/views/auth/app-register.vue';
import AppRestorePassword from '@/views/auth/app-restore-password.vue';
import AuthLayout from '@/views/auth/layout/auth-layout.vue';
import ViewContact from '@/views/ContactsPage.vue';
import ViewUserCreate from '@/views/dashboard/user-create.vue';
import ViewUserManagement from '@/views/dashboard/user-management.vue';
import GarageCar from '@/views/garage/car.vue';
import Garage from '@/views/garage/index.vue';
import ViewOrderHistory from '@/views/history/OrderHistory.vue';
import LaximoMainPage from '@/views/laximo/LaximoMainPage.vue';
import LaximoModels from '@/views/laximo/LaximoModels.vue';
import LaximoUnit from '@/views/laximo/LaximoUnit.vue';
import LaximoCatalogs from '@/views/laximo/layout/LaximoCatalogs.vue';
import NewSite from '@/views/NewSite.vue';
import NotFound from '@/views/NotFound.vue';
import OrderCreate from '@/views/order/OrderCreate.vue';
import ReturnOrder from '@/views/order/ReturnOrder.vue';
import OrderItems from '@/views/orderItems/OrderItems';
import ViewPart from '@/views/parts/ViewPart.vue';
import PromoPage from '@/views/products/PromoPage';
import ViewProducts from '@/views/products/ViewProducts.vue';
import Receivables from '@/views/receivables/Receivables';
import ReconciliationReports from '@/views/reconciliationReports/index.vue';
// region views imports
import SearchResults from '@/views/searchResults.vue';
import ViewPersonal from '@/views/settings/PersonalSettings.vue';
import ShopProductsPage from '@/views/shops/ShopProducts.vue';
import ShopsPage from '@/views/shops/Shops.vue';
import TecdocBrands from '@/views/tecdoc/Brands.vue';
import TecdocCategories from '@/views/tecdoc/Categories.vue';
import TecdocLayout from '@/views/tecdoc/layout/tecdoc-layout.vue';
import TecdocModels from '@/views/tecdoc/Models.vue';
import TecdocModifications from '@/views/tecdoc/Modifications.vue';
import WishList from '@/views/wishlist/WishList.vue';
import Xls from '@/views/xls/Xls.vue';
import XlsErrors from '@/views/xls/XlsErrors.vue';

import auth from './middleware/auth';
// endregion
// region middlewares
import guest from './middleware/guest';
import noAuth from './middleware/noAuth';
import pipeline from './middleware/pipeline';
// endregion

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter);

// region config
const routes = [
  {
    path: '/auth',
    name: 'auth-layout',
    redirect: '/auth/login',
    component: AuthLayout,
    meta: {
      middleware: [
        noAuth,
      ],
    },
    children: [
      {
        path: 'login',
        name: 'app-login',
        component: AppLogin,
        meta: {
          middleware: [
            noAuth,
          ],
        },
      },
      {
        path: 'registration',
        name: 'app-register',
        component: AppRegister,
        meta: {
          middleware: [
            noAuth,
          ],
        },
      },
      {
        path: 'restore-password',
        name: 'app-restore-password',
        component: AppRestorePassword,
        meta: {
          middleware: [
            noAuth,
          ],
        },
      },
    ],
  },
  {
    path: '/tecdoc',
    name: 'tecdoc-layout',
    redirect: '/tecdoc/brand',
    component: TecdocLayout,
    meta: {
      middleware: [
        auth,
      ],
    },
    children: [
      {
        path: '/tecdoc/brand',
        name: 'tecdoc-brand',
        component: TecdocBrands,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: '/tecdoc/model/:manufacturerId',
        name: 'tecdoc-model',
        component: TecdocModels,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: '/tecdoc/modifications/:manufacturerId/:modelId',
        name: 'tecdoc-modifications',
        component: TecdocModifications,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: '/tecdoc/catalog/:modificationId',
        name: 'tecdoc-catalog',
        component: TecdocCategories,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
    ]
  },
  {
    path: '/find',
    name: 'search-results',
    component: SearchResults,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    // old '/dashboard/view-products',
    path: '/',
    redirect: '/products',
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    // old '/dashboard/view-products',
    path: '/products/:category?',
    name: 'view-products',
    component: ViewProducts,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    // old '/dashboard/view-products',
    path: '/wishlist',
    name: 'view-wishlist',
    component: WishList,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    // old: '/dashboard/view-order',
    path: '/order',
    name: 'view-order',
    component: OrderCreate,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/return-order',
    name: 'return-order',
    component: ReturnOrder,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    // old: '/dashboard/view-order-history',
    path: '/history',
    name: 'view-order-history',
    component: ViewOrderHistory,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    // old:'/dashboard/view-contact'
    path: '/contacts',
    name: 'view-contact',
    component: ViewContact,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    // old: '/dashboard/view-personal',
    path: '/settings',
    name: 'view-personal',
    component: ViewPersonal,
    meta: {
      middleware: [
        auth,
        guest
      ],
    },
  },
  { /* Убрать в будущем */
    path: '/dashboard/view-products',
    redirect: '/products',
  },
  {
    path: '/admin',
    name: 'app-layout',
    component: AdminLayout,
    meta: {
      middleware: [
        auth,
      ],
    },
    children: [
      {
        path: '/',
        name: 'admin-dashboard',
        component: Admin,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: 'logs',
        name: 'view-admin',
        component: ViewAdmin,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: 'users',
        name: 'view-admin-users',
        component: ViewAdminUsers,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: 'changelog',
        name: 'view-admin-changelog',
        component: ViewAdminChangelog,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: 'synonyms',
        name: 'view-admin-synonyms',
        component: ViewAdminSynonyms,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: 'history-backup',
        redirect: '/admin/users'
      },
      {
        path: 'history-backup/:id',
        name: 'admin-history-backup',
        component: AdminBackupHistory,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: '/dashboard/user-management',
        name: 'user-management',
        component: ViewUserManagement,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
      {
        path: '/dashboard/user-create',
        name: 'user-create',
        component: ViewUserCreate,
        meta: {
          middleware: [
            auth,
          ],
        },
      },
    ],
  },
  {
    path: '/upload-xls',
    name: 'upload-xls',
    component: Xls,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/upload-xls-errors',
    name: 'upload-xls-errors',
    component: XlsErrors,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/new-site',
    name: 'new-site',
    component: NewSite,
  },
  {
    path: '/oem',
    component: LaximoCatalogs,
    meta: {
      middleware: [
        auth,
      ],
    },
    children: [
      {
        path: '/',
        name: 'laximo',
        component: LaximoMainPage
      },
      {
        path: ':name',
        name: 'laximoModels',
        component: LaximoModels
      },
      {
        path: ':name/:unit',
        name: 'LaximoUnit',
        component: LaximoUnit
      },
    ]
  },
  {
    path: '/part/:brand/:article',
    name: 'parts',
    component: ViewPart
  },
  {
    path: '/receivables',
    name: 'receivables',
    component: Receivables,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/order-items',
    name: 'orderItems',
    component: OrderItems,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/promo',
    name: 'promo-actions',
    component: PromoPage,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/shops',
    name: 'shops',
    component: ShopsPage,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/shops/:shopId',
    name: 'ShopProducts',
    component: ShopProductsPage,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/reconciliationReports',
    name: 'reconciliationReports',
    component: ReconciliationReports
  },
  {
    path: '/garage',
    name: 'garage',
    component: Garage,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/garage/:car',
    name: 'car',
    component: GarageCar,
    meta: {
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  }, {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const { middleware } = to.meta;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    nextMiddleware: pipeline(context, middleware, 1),
  });
});

router.onError((error) => {
  const pattern = /Loading chunk (\S)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    console.log('Chunk loading failed, reloading page..');
    const LoadingChunk = window.sessionStorage.getItem('LoadingChunk');
    if (LoadingChunk) {
      window.sessionStorage.setItem('LoadingChunk', LoadingChunk + 1);
    } else {
      window.sessionStorage.setItem('LoadingChunk', '1');
    }
    if (LoadingChunk > 1) {
      // Prevent infinite loop
      return;
    }
    router.replace(targetPath);
  }
});


export default router;
