<template>
  <div class="filter">
    <span class="filter__title">{{ filter.title }}</span>
    <div class="filter__slider">
      <el-slider
        v-model="minmax"
        range
        :step="userCurrency === 'RU' ? 50 : 500"
        :min="filter.values[currency()][0]"
        :max="filter.values[currency()][1]"
      />
    </div>
    <div class="filter__inputs">
      <el-input
        :value="`${inputMin}${userCurrency === 'KZ' ? ' ₸' : ''}`"
        size="small"
        @input="inputMin = genNumber($event)"
        @change="min = inputMin"
      />
      <el-input
        :value="`${inputMax}${userCurrency === 'KZ' ? ' ₸' : ''}`"
        size="small"
        @input="inputMax = genNumber($event)"
        @change="max = inputMax"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'FilterRange',
  props: {
    filter: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      value: this.getValue(),
      inputMin: 0,
      inputMax: Number(this.filter.values[this.currency()][1]),
    };
  },
  computed: {
    ...mapState('ui', ['userCurrency']),
    min: {
      set(val) {
        let value = this.genNumber(val);
        if (value >= this.value[1]) value = this.value[1] - 1;
        this.value = [value, this.value[1]];
        this.setFilters();
      },
      get() {
        return this.value[0];
      }
    },
    max: {
      set(val) {
        let value = this.genNumber(val);
        if (this.value[0] >= value) value = this.value[0] + 1;
        this.value = [this.value[0], value];
        this.setFilters();
      },
      get() {
        return this.value[1];
      }
    },
    minmax: {
      set(val) {
        this.inputMin = val[0];
        this.inputMax = val[1];
        if (val[0] !== this.value[0]) this.min = val[0];
        if (val[1] !== this.value[1]) this.max = val[1];
      },
      get() {
        return [this.min, this.max];
      }
    },
  },
  watch: {
    userCurrency(val) {
      const newCurrencyRub = val === 'RU';
      const { tengeCourse } = this.filter.values;
      const min = (newCurrencyRub ? this.value[0] / +tengeCourse : this.value[0] * +tengeCourse).toFixed(2);
      const max = (newCurrencyRub ? this.value[1] / +tengeCourse : this.value[1] * +tengeCourse).toFixed(2);
      this.value = [min, max];
      this.inputMin = 0;
      this.inputMax = Number(this.filter.values[this.currency()][1]);
    },
    filter() {
      this.value = this.getValue();
      this.inputMax = Number(this.filter.values[this.currency()][1]);
    }
  },
  mounted() {
    this.$root.$on('resetFilters', () => {
      this.value = this.filter.values[this.currency()];
    });
  },
  methods: {
    ...mapMutations('products', ['SET_CHANGED_FILTERS', 'SET_FILTER_CHANGE']),
    getValue() {
      const { price } = this.$route.query;
      if (price) {
        let filterPrice = [+price.split('-')[0], +price.split('-')[1]];
        if (localStorage.getItem('userCurrency') === 'KZ') {
          filterPrice = filterPrice.map(item => (item * +this.filter.values.tengeCourse).toFixed(2));
        }
        return filterPrice;
      }
      return this.filter.values[this.currency()];
    },
    currency() {
      return this.userCurrency === 'RU' ? 'rub' : 'tenge';
    },
    setFilters() {
      this.SET_FILTER_CHANGE(true);

      const { value, filter } = this;
      if (value[0] === Number(filter.values[0]) && value[1] === Number(filter.values[1])) {
        this.SET_CHANGED_FILTERS({
          filter,
          remove: true,
        });
        return;
      }
      const { tengeCourse } = this.filter.values;
      this.SET_CHANGED_FILTERS({
        filter: {
          id: filter.id,
          name: filter.name,
          gt: value[0] !== Number(filter.values[0])
            ? (this.userCurrency === 'RU'
              ? value[0]
              : value[0] / +tengeCourse)
            : undefined,
          lt: value[1] !== Number(filter.values[1])
            ? (this.userCurrency === 'RU'
              ? value[1]
              : value[1] / +tengeCourse)
            : undefined,
        },
      });
    },
    genNumber(value) {
      if (typeof value === 'string') {
        return Number(value.replaceAll(/[^(\d|\.)]/g, ''));
      }

      if (typeof value === 'number') return value;
      return 0;
    }
  }
};
</script>

<style scoped lang="sass">
  .filter
    &__title
      font-size: 11px
      font-weight: bold
    &__inputs
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 20px
    &__slider
      padding: 0 5px
      margin-top: -5px

</style>

<style lang="sass">
  .filter .el-slider__stop
    background: #e4e7ed
</style>
