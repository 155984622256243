<template>
  <div :class="b()" :style="basketStyle" v-loading="basketsLoading">
    <div class="v-step-45" v-if="windowSize.width < 900" :class="b('buttons', { size, small: true })">
      <button
        :class="b('buttons__button', { active: size === 'big' })"
        @click="changeBasketSize(windowSize.width > 900 ? 'big' : basketSize === 'big' ? 'small' : 'big')"
      >
        <i class="el-icon-caret-top" />
      </button>
      <button
        v-if="windowSize.width > 900"
        :class="b('buttons__button', { active: size === 'default' })"
        @click="changeBasketSize('default')"
      >
        <i class="el-icon-more" />
      </button>
      <button
        :class="b('buttons__button', { active: size === 'small' })"
        @click="changeBasketSize(windowSize.width > 900 ? 'small' : basketSize === 'big' ? 'small' : 'big')"
      >
        <i class="el-icon-caret-bottom" />
      </button>
    </div>

    <BasketTable :basket-size="size" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import BasketTable from './subcomponents/basketTable';

export default {
  name: 'BasketAside',
  components: {
    BasketTable,
  },
  computed: {
    ...mapState('ui', ['basketSize', 'windowSize', 'userCurrency']),
    ...mapState('basket', ['basket', 'activeBasketId', 'basketsLoading']),
    size() {
      if (this.basketSize === 'default' && this.windowSize.width < 900) return 'small';
      return this.basketSize;
    },
    basketStyle() {
      return {
        height: (() => {
          switch (this.size) {
          case 'small': return '92px';
          case 'big': return '100%';
          default: return '252px';
          }
        })()
      };
    },
  },
  mounted() {
    this.GetBasketsWithItems()
      .then(async (resp) => {
        if (!resp.length) {
          return;
        }
        const basketIdFromLocalStorage = Number(localStorage.getItem('activeBasketId'));
        const isBasketExistsInResponse = basketIdFromLocalStorage
          && resp.find(cart => Number(cart.id) === basketIdFromLocalStorage) !== undefined;

        if (isBasketExistsInResponse) {
          this.SET_ACTIVE_BASKET(basketIdFromLocalStorage);
        } else {
          this.SET_ACTIVE_BASKET(resp[0].id);
          this.SET_RETURN_BASKET_ID(null);
        }
        await this.GetProductsBasket();
      });
    this.GetReturnProductsBaskets();
  },
  methods: {
    ...mapMutations('ui', ['CHANGE_BASKET_SIZE']),
    ...mapMutations('basket', ['SET_ACTIVE_BASKET']),
    ...mapMutations('returns', ['SET_RETURN_BASKET_ID']),
    ...mapActions('basket', ['GetBasketsWithItems', 'GetProductsBasket']),
    ...mapActions('returns', ['GetReturnProductsBaskets']),
    changeBasketSize(size) {
      this.CHANGE_BASKET_SIZE(size);
    },
  }
};
</script>

<style scoped lang="sass">
  .basket-aside
    position: sticky
    background-color: #fff
    border-top: 1px solid #EBEEF5
    height: 252px
    width: 100%
    margin-top: auto
    flex-shrink: 0
    transition: height .2s
    bottom: 0
    z-index: 99
    &__buttons
      position: absolute
      display: flex
      flex-flow: column
      right: 8px
      top: -8px
      background: white
      box-shadow: 0 0 6px rgba(0, 0, 34, 0.15)
      border-radius: 6px
      border: 1px solid #ebeef5
      z-index: 101
      transition: .2s
      &_small
        &.basket-aside__buttons_size-small
          top: 6px
        &.basket-aside__buttons_size-big
          top: 4px
      &_size
        &-small
          top: -12px
          box-shadow: 0 -6 6px rgba(0, 0, 34, 0.15)
        &-big
          top: 2px
          box-shadow: 0 6px 6px rgba(0, 0, 34, 0.15)
      &__button
        border-radius: 0
        padding: 1px 4px
        border: none
        background: white
        color: #6d7075
        cursor: pointer
        transition: .2s
        &:first-child
          border-radius: 6px 6px 0 0
        &:last-child
          border-radius: 0 0 6px 6px
        &_active
          color: #1f75a8
          background: rgba(#1f75a8, .2)
</style>
